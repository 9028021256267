<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div v-if="isSuperAdmin">
        <soft-button
          data-bs-toggle="modal"
          data-bs-target="#New"
          color="success"
          variant="gradient"
        >
          New order
          <span class="btn-inner--icon"> <i class="ni ni-diamond"></i> </span
        ></soft-button>
      </div>
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="pdf"
          @click="exportReport"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export Report</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- <div class="card">
          <tracker></tracker>
        </div> -->
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#, Client, Customer, Store"
              >
              </soft-input>
              <div
                v-click-outside="() => (toggleColorDropdown = false)"
                class="color-key-container"
              >
                <div class="">
                  <div class="" id="headingOne">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        href="#color-key-dropdown"
                        aria-controls="color-key-dropdown"
                        aria-expanded="false"
                        class="btn btn-link"
                        v-bind="$attrs"
                        type="button"
                        @click="toggleColorDropdown = !toggleColorDropdown"
                      >
                        Color Key
                      </a>
                    </h5>
                  </div>

                  <div
                    id="color-key-dropdown"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="color-key-dropdown">
                      <div
                        class="color-key-list"
                        v-for="(color, status) in statusColors"
                        :key="status"
                      >
                        <div
                          @click="filterByColor"
                          :style="{
                            backgroundColor: color,
                            cursor: 'pointer',
                            color: status.includes('Delayed')
                              ? 'white'
                              : 'black',
                          }"
                          class="color-status"
                        >
                          {{ status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="light">
              <div class="filters">
                <div v-if="isSuperAdmin || isEmployee" class="filter">
                  <p class="filter-label">Clients</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.client"
                    :options="clients"
                    label="company"
                    :reduce="(t) => t.id"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <div class="filter">
                  <p class="filter-label">Status</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.status"
                    :options="[
                      'Order Created',
                      'Factory Received Order',
                      'In Process',
                      'Shipped To DIS',
                      'Delayed',
                      'DIS Received Order',
                      'QA Staging',
                      'In Vault',
                      'Shipped To Store',
                    ]"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <div v-if="isSuperAdmin || isEmployee" class="filter">
                  <p class="filter-label">Sales Rep</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.rep"
                    :options="salesReps"
                    :reduce="(s) => s.id"
                    :closeOnSelect="true"
                    label="firstName"
                  >
                  </v-select>
                </div>

                <div class="filter">
                  <p class="filter-label">Start From:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'starDate')"
                    class="datetime-input"
                    v-model="filterBy.startDate"
                  />
                </div>
                <div class="filter">
                  <p class="filter-label">End At:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'endDate')"
                    class="datetime-input"
                    v-model="filterBy.endDate"
                  />
                </div>

                <div class="filter filter-btn">
                  <soft-button
                    type="button"
                    color="primary"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="handleFilter"
                  >
                    Filter
                  </soft-button>
                  <soft-button
                    type="button"
                    color="warning"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="clearFilters"
                  >
                    Clear
                  </soft-button>
                </div>
              </div>
            </div>

            <div class="table-wrapper">
              <table id="order-list" class="table">
                <thead class="thead-light">
                  <tr>
                    <th>Controls</th>
                    <th v-if="isClient">
                      <soft-button
                        @click="showUnpaidOrderTable"
                        color="secondary"
                        variant="gradient"
                        type="button"
                        name="button"
                        >Pay Multiple Invoice</soft-button
                      >
                      <div>Payment Status</div>
                    </th>
                    <th>Date/Time</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>Sales Rep</th>
                    <th>Client</th>
                    <th>Store ID</th>
                    <th>Consumer</th>
                    <th>Item Type</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Store's Cost</th>
                    <th>Order Total Cost</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                    :style="{
                      backgroundColor: statusColors[order.status],
                      color: order.status.includes('Delayed')
                        ? 'white'
                        : 'black',
                      'font-weight': 900,
                    }"
                  >
                    <td class="align-items-center">
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        <soft-button
                          :disabled="anOrderHasBeenSelected"
                          size="md"
                          color="secondary"
                          variant="outline"
                          style="border: none"
                          class="btn-icon-only align-items-center justify-content-center"
                          data-bs-original-title="Preview collection"
                          @click="handleModalInitilization('Details', order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            title="View Order Details"
                            :style="{
                              color: order.status.includes('Delayed')
                                ? 'white'
                                : 'black',
                            }"
                            class="fas fa-eye act-icon"
                          ></i>
                        </soft-button>
                        <soft-button
                          size="md"
                          color="secondary"
                          variant="outline"
                          style="border: none"
                          class="btn-icon-only align-items-center justify-content-center"
                          @click="printOrderConfirmation(order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            title="Print Order Confirmation"
                            :style="{
                              color: order.status.includes('Delayed')
                                ? 'white'
                                : 'black',
                            }"
                            class="fas fa-print act-icon"
                          ></i>
                        </soft-button>
                        <soft-button
                          v-if="isClient"
                          :disabled="anOrderHasBeenSelected"
                          size="md"
                          color="secondary"
                          variant="outline"
                          style="border: none"
                          class="btn-icon-only align-items-center justify-content-center"
                          @click="handleModalInitilization('Invoice', order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            title="View Invoice"
                            :style="{
                              color: order.status.includes('Delayed')
                                ? 'white'
                                : 'black',
                            }"
                            class="fas fa-dollar-sign act-icon"
                          ></i>
                        </soft-button>
                      </p>
                    </td>
                    <td v-if="isClient" class="font-weight-bold">
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p
                          v-if="order.paymentStatus != 'Not Paid'"
                          class="text-xs font-weight-bold ms-2 mb-0"
                        >
                          {{ order.paymentStatus }}
                        </p>
                        <soft-button
                          v-if="order.paymentStatus == 'Not Paid'"
                          @click="handleModalInitilization('PayPortal', order)"
                          color="info"
                          variant="gradient"
                          type="button"
                          name="button"
                          >Pay Invoice</soft-button
                        >
                      </div>
                    </td>
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.client.company }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.consumer.storeId }}
                          <!-- {{ order.customer.storeId }} -->
                        </p>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{
                            `${order.consumer.firstName} ${order.consumer.lastName}`
                          }}
                          <!-- {{
                            `${order.customer.firstName} ${order.customer.lastName}`
                          }} -->
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.quantity }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            format_price(order.priceBreakdown.storeTotalCost)
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_price(order.consumerTotalPrice) }}
                        </p>
                      </div>
                    </td>
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    @close="handleModalClose('Details')"
    id="Details"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>Order Details</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails
          v-if="anOrderHasBeenSelected"
          @handle-input="handleOrderInput"
          :order="selectedOrder"
        />
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-if="showSaveChanges"
        @click="saveChanges"
        type="button"
        class="btn btn-primary"
      >
        Save changes
      </button>
      <button
        v-if="isSuperAdmin"
        @click="sendConfirmationEmail"
        type="button"
        class="btn btn-primary"
      >
        Send Confrimation Email
      </button>
    </template>
  </modal>

  <modal
    @close="closeModal('New')"
    id="New"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>New Order</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <NewOrder :clients="clients"></NewOrder>
      </div>
    </template>
    <template v-if="showSaveChanges" v-slot:footer>
      <button @click="saveChanges" type="button" class="btn btn-primary">
        Save changes
      </button>
    </template>
  </modal>

  <modal
    @close="handleModalClose('Invoice')"
    id="Invoice"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>Order #: {{ selectedOrder.id }} Invoice</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <ClientInvoice :order="selectedOrder"></ClientInvoice>
      </div>
    </template>
    <template v-slot:footer>
      <soft-button
        v-if="selectedOrder.paymentStatus == 'Not Paid'"
        color="info"
        variant="gradient"
        data-bs-toggle="modal"
        data-bs-target="#PayPortal"
        type="button"
        name="button"
        >Pay Invoice</soft-button
      >
      <soft-button
        color="warning"
        variant="gradient"
        onclick="window.print()"
        type="button"
        name="button"
        >Print Invoice</soft-button
      >
    </template>
  </modal>

  <modal
    @close="handleModalClose('PayPortal')"
    id="PayPortal"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>Order #: {{ selectedOrder.id }} Invoice</h2>
    </template>
    <template v-slot:body>
      <h3 style="text-align: center">Select Payment Type</h3>
      <div class="order-details-wrapper">
        <PayPortal
          v-if="anOrderHasBeenSelected"
          @handle-multiple-on-closer="handleModalClose('PayPortal')"
          :multiplePayments="false"
          :selectedOrder="selectedOrder"
          :selectedOrders="selectedUnpaidOrders"
        ></PayPortal>
      </div>
    </template>
    <template v-slot:footer>
      <!-- <soft-button
        v-if="selectedOrder.paymentStatus == 'Not Paid'"
        color="info"
        variant="gradient"
        @click="showPaymentModal = true"
        type="button"
        name="button"
        >Pay</soft-button
      > -->
    </template>
  </modal>

  <modal
    @close="handleMultipleInvoiceOnCloser"
    id="PayMultInvoices"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>Pay Multiple Invoice</h2>
    </template>
    <template v-slot:body>
      <div v-if="showInvoiceTable" class="order-details-wrapper">
        <InvoiceTable
          @handle-selected-order="handleSelectedOrderForMultPayment"
          :tableData="unpaidOrders"
        ></InvoiceTable>
      </div>

      <div v-else class="order-details-wrapper">
        <h3 style="text-align: center">Select Payment Type</h3>
        <PayPortal
          @handle-multiple-on-closer="handleMultipleInvoiceOnCloser"
          :multiplePayments="true"
          :selectedOrders="selectedUnpaidOrders"
        ></PayPortal>
      </div>
    </template>

    <template v-slot:footer>
      <soft-button
        color="info"
        variant="gradient"
        v-if="selectedUnpaidOrders.length && showInvoiceTable"
        @click="showMultipleInvoicePayPortal"
        type="button"
        name="button"
        >Pay</soft-button
      >
    </template>
  </modal>

  <OrderReceipt
    :printOrderReceipt="printOrderReceipt"
    class="hidden-containers"
    ref="order_receipt_container"
    :order="selectedOrder"
  ></OrderReceipt>

  <OrderReports
    class="hidden-containers"
    ref="report_container"
    v-if="initReportExport"
    :orders="queriedData"
  >
  </OrderReports>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import OrderDetails from "@/views/ecommerce/Orders/OrderDetails.vue";
import OrderReceipt from "@/components/OrderReceipt.vue";
import OrderReports from "@/components/Reports/OrderReports.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import NewOrder from "./NewOrder.vue";
import ClientInvoice from "./ClientInvoice.vue";
import PayPortal from "./PayPortal.vue";
import InvoiceTable from "./components/PayMultInvoices/InvoiceTable.vue";

import axios from "axios";
import { DateTime } from "luxon";

import {
  collection,
  doc,
  db,
  orderBy,
  query,
  updateDoc,
  onSnapshot,
  where,
  endAt,
  startAt,
  limit,
  getDoc,
} from "@/config/firebaseInit.js";
import { mapState } from "vuex";

export default {
  name: "OrderList",
  components: {
    OrderReceipt,
    OrderReports,
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
    OrderDetails,
    NewOrder,
    ClientInvoice,
    PayPortal,
    InvoiceTable,
  },
  data() {
    return {
      anOrderHasBeenSelected: false,
      toggleColorDropdown: false,
      openNewOrderModal: false,
      initReportExport: false,
      statusColors: {
        "Order Created": "#58E287",
        "In Process": "rgb(255, 239, 0)",
        "Shipped To DIS": "rgb(231, 66, 236)",
        "DIS Received Order": "rgb(180, 89, 255)",
        // "QA Staging": "#9cb0ff",
        "Shipped To Store": "rgb(0, 243, 255)",
        Cancelled: "#f76a6a",
        Delayed: "black",
        Completed: "rgb(73, 127, 255)",
      },
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      ordersRef: {},
      tableData: [],
      searchedData: [],
      orders: [],
      salesReps: [],
      clients: [],
      modalIds: {},
      showModal: false,
      printOrderReceipt: false,
      showSaveChanges: false,
      selectedOrder: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      unpaidOrders: [],
      selectedUnpaidOrders: [],
      showInvoiceTable: false,
      bccEmails: ["tlusa18@gmail.com", "sam@ttcusadiamond.com"],
      factoryEmails: [],
      filterBy: {
        client: "",
        status: "",
        rep: "",
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    toggleColorDropdown(val) {
      let myCollapse = document.getElementById("color-key-dropdown");
      if (!val) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          let bsCollapse = new bootstrap.Collapse(myCollapse, {
            toggle: false,
          });
        }, 500);
      } else {
        let bsCollapse = new bootstrap.Collapse(myCollapse, {
          toggle: true,
        });
      }
    },
  },
  created() {
    this.fetchData("start");

    if (this.isSuperAdmin || this.isEmployee) {
      this.getAllClients();
      this.getAllSalesReps();
      this.fetchBCCEmails();
    }
  },
  methods: {
    handleMultipleInvoiceOnCloser() {
      this.showInvoiceTable = true;
      this.selectedUnpaidOrders = [];
      this.handleModalClose("PayMultInvoices");
    },
    showMultipleInvoicePayPortal() {
      this.showInvoiceTable = false;
    },
    handleSelectedOrderForMultPayment(data) {
      this.selectedUnpaidOrders = data;
    },
    showUnpaidOrderTable() {
      this.unpaidOrders = this.tableData.filter(
        (order) => order.paymentStatus == "Not Paid"
      );
      this.showInvoiceTable = true;
      this.handleModalInitilization("PayMultInvoices");
    },
    async sendConfirmationEmail() {
      const date = new Date();
      const createdAt = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
      const client = this.clients.find(
        (c) => c.id == this.selectedOrder.client.id
      );
      let displayImage = this.selectedOrder.images.filter(
        (i) => i.type === "image"
      )[0];

      displayImage = displayImage ? displayImage.url : "";
      try {
        const res = await axios.post(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/LunaCollectionServer/send_order_confirmation`,

          {
            data: {
              contents: [
                {
                  template_id: 2,
                  csv: false,
                  subject: "Luna Collection Order Confirmation Receipt",
                  filename: null,
                  to: [client.email],
                  cc: [this.selectedOrder.salesRep.email],
                  bcc: this.bccEmails,
                  params: {
                    productImage: displayImage.url,
                    clientName: this.selectedOrder.client.fullName,
                    consumerPhone: this.selectedOrder.consumer.phone,
                    consumerEmail: this.selectedOrder.consumer.email,
                    consumerFirstName: this.selectedOrder.consumer.firstName,
                    consumerLastName: this.selectedOrder.consumer.lastName,
                    purchaseOrderNumber: this.selectedOrder.purchaseOrderNumber,
                    orderNumber: this.selectedOrder.orderNumber,
                    createdAt,
                    itemDescription: `${this.selectedOrder.origin} ${
                      this.selectedOrder.jewelryType == "Ring"
                        ? `Size ${this.selectedOrder.size}`
                        : ""
                    }  ${this.selectedOrder.caratWeight}ct t.w ${
                      this.selectedOrder.shape
                    } ${this.selectedOrder.metal.name} ${
                      this.selectedOrder.style
                    } ${this.selectedOrder.jewelryType}`,
                    sku: this.selectedOrder.sku,
                    carat: this.selectedOrder.caratWeight.toFixed(2),
                    quality: this.selectedOrder.quality,
                    engravingsFont: this.selectedOrder.engravings
                      ? this.selectedOrder.engravings.font
                      : "",
                    engravingsText: this.selectedOrder.engravings
                      ? this.selectedOrder.engravings.text
                      : "",
                    birthStones: this.selectedOrder.birthStones,
                    quantity: this.selectedOrder.quantity,
                    totalCost: this.format_price(this.selectedOrder.totalCost),
                  },
                },

                {
                  template_id: 2,
                  csv: false,
                  subject:
                    "Luna Collection Order Confirmation Receipt For Trezza",
                  filename: null,
                  cc: this.factoryEmails,
                  to: [this.factoryEmails[0]],
                  params: {
                    productImage: displayImage.url,
                    clientName: this.selectedOrder.client.fullName,
                    consumerFirstName: this.selectedOrder.consumer.firstName,
                    consumerLastName: this.selectedOrder.consumer.lastName,
                    purchaseOrderNumber: this.selectedOrder.purchaseOrderNumber,
                    orderNumber: this.selectedOrder.orderNumber,
                    createdAt,
                    itemDescription: `${this.selectedOrder.origin} ${
                      this.selectedOrder.jewelryType == "Ring"
                        ? `Size ${this.selectedOrder.size}`
                        : ""
                    }  ${this.selectedOrder.caratWeight}ct t.w ${
                      this.selectedOrder.shape
                    } ${this.selectedOrder.metal.name} ${
                      this.selectedOrder.style
                    } ${this.selectedOrder.jewelryType}`,
                    sku: this.selectedOrder.sku,
                    carat: this.selectedOrder.caratWeight.toFixed(2),
                    quality: this.selectedOrder.quality,
                    engravingsFont: this.selectedOrder.engravings
                      ? this.selectedOrder.engravings.font
                      : "",
                    engravingsText: this.selectedOrder.engravings
                      ? this.selectedOrder.engravings.text
                      : "",
                    birthStones: this.selectedOrder.birthStones,
                    quantity: this.selectedOrder.quantity,
                    totalCost: this.format_price(this.selectedOrder.totalCost),
                  },
                },
              ],
            },
          }
        );

        return this.$swal.fire("Email Has Been Sent");
      } catch (err) {
        // orderFeedback.value = err.message;
        console.debug(err);
        this.displayError(err);
      }
    },
    async fetchBCCEmails() {
      const bccData = await getDoc(
        doc(collection(db, "attributes"), "bcc_emails")
      );
      const factoryEmails = await getDoc(
        doc(collection(db, "attributes"), "factory_emails")
      );

      if (bccData.exists()) this.bccEmails = bccData.data().emails;
      if (factoryEmails.exists())
        this.factoryEmails = factoryEmails.data().emails;
    },
    closeModal(id) {
      this.modalIds[id].hide();
      this.modalIds[id].dispose();
    },
    openModal(id) {
      setTimeout(() => {
        this.modalIds[id] = new bootstrap.Modal(`#${id}`);
        this.modalIds[id].show();
      }, 1000);
    },
    handleModalInitilization(modalId, order) {
      if (order) {
        this.anOrderHasBeenSelected = true;
        this.selectedOrder = JSON.parse(JSON.stringify(order));
      }
      this.openModal(modalId);
    },
    handleModalClose(modalId) {
      this.anOrderHasBeenSelected = false;
      this.selectedOrder = {};
      setTimeout(() => {
        this.closeModal(modalId);
      }, 500);
    },
    updateTimestamp(event, key) {
      this.filterBy[key] = event.target.value;
    },
    async handleFilter() {
      let timeFilter = false;

      let orderQuery = collection(db, "orders");

      if (
        !this.filterBy.client &&
        !this.filterBy.status &&
        !this.filterBy.rep &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        // this.resetTable();

        return false;
      }

      try {
        if (this.filterBy.startDate && this.filterBy.endDate) {
          timeFilter = true;
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          orderQuery = query(
            orderQuery,
            orderBy("createdAt", "desc"),
            startAt(endDate),
            endAt(startDate)
          );
        }

        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "client") {
                  orderQuery = query(
                    orderQuery,
                    where("client.id", "==", element)
                  );
                }

                if (key == "status") {
                  orderQuery = query(
                    orderQuery,
                    where("status", "==", element)
                  );
                }

                if (key == "rep") {
                  orderQuery = query(
                    orderQuery,
                    where("salesRep.id", "==", element)
                  );
                }
              }
            }
          }
        }

        if (timeFilter) {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        } else {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        }
      } catch (error) {
        console.debug(error);

        return this.$swal({
          icon: "error",
          title: "Internal Issue",
          text: "Oops there seems to be an issue please try again or contact support at info@mylunacollection.com",
          type: "error",
        });
      }
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "client" || key === "status") {
          this.filterBy[key] = "";
        } else if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    async printOrderConfirmation(order) {
      this.printOrderReceipt = true;
      this.selectedOrder = JSON.parse(JSON.stringify(order));
      setTimeout(async () => {
        await this.fetchProductImage(order);
        await this.exportToPDF();
      }, 500);
    },

    async fetchProductImage(order) {
      try {
        const image = order.images.filter((i) => i.type.includes("image"));
        const displayedImage = image[0] || null;
        this.selectedOrder.productImageDataUrl =
          await this.getBase64ImageFromUrl(displayedImage.url);
      } catch (error) {
        console.debug("Error fetching product image:", error);
      }
    },
    getBase64ImageFromUrl(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = function () {
          reject("Error loading image");
        };
        img.src = url;
      });
    },
    async exportToPDF() {
      const order_container_ref = this.$refs.order_receipt_container;
      const pdf = new jsPDF();
      const content = order_container_ref.$refs.contentToExport; // Replace with your element's ref or selector
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 10, 200, 200);
      pdf.save(`${this.selectedOrder.purchaseOrderNumber}.pdf`);
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");

      this.printOrderReceipt = false;
      this.selectedOrder = {};
    },

    exportReport() {
      this.initReportExport = true;
      setTimeout(async () => {
        const report_container_ref = this.$refs.report_container;
        const reportContent = report_container_ref.$refs.reportContent;
        const canvas = await html2canvas(reportContent);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("report.pdf");
        this.initReportExport = false;
      });
    },
    async saveChanges() {
      const data = this.selectedOrder.shippingDetails;
      if (data.carrier && data.trackingNumber) {
        await updateDoc(doc(db, "orders", this.selectedOrder.id), {
          shippingDetails: data,
          status: this.selectedOrder.status,
        }).then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          });
        });
      } else {
        this.$swal({
          icon: "error",
          title: "Missing Information",
          text: "Must have both a tracking # and a carrier",
          type: "error",
        });
      }
    },
    handleOrderInput(e, key, subKey) {
      this.showSaveChanges = true;
      if (subKey) this.selectedOrder[key][subKey] = e.target.value;
      else this.selectedOrder[key] = e.target.value;
    },
    addCTTW(sku) {
      const cctw = sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(orderRef, orderBy("createdAt", "desc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.orders = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        this.tableData = this.orders;
      });
    },
    async getAllClients() {
      const orderRef = collection(db, "clients");
      const orderQuery = query(orderRef, orderBy("company", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.clients = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    async getAllSalesReps() {
      const orderRef = collection(db, "sales_rep");
      const orderQuery = query(orderRef, orderBy("firstName", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.salesReps = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },

    searchForOrder(e) {
      if (!e.target.value) {
        this.tableData = this.order;
      }
      let input = e.target.value.toUpperCase();
      const validateOrder = (order) => {
        if (
          order.client.company.toUpperCase().includes(input) ||
          order.orderNumber.toUpperCase().includes(input) ||
          order.sku.toUpperCase().includes(input) ||
          order.purchaseOrderNumber.toUpperCase().includes(input) ||
          order.consumer.email.toUpperCase().includes(input) ||
          `${order.consumer.name} ${order.consumer.lastName}`
            .toUpperCase()
            .includes(input)
          // order.storeId.includes(input) ||
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.orders.filter((order) => validateOrder(order));
    },

    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },

    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const orderRef = collection(db, "orders");
          let orderQuery;
          if (this.isSuperAdmin || this.isEmployee) {
            orderQuery = query(
              orderRef,
              orderBy("createdAt", "desc"),
              endAt(0),
              limit(this.pagination.perPage)
            );
          } else {
            let queryfield;
            let queryCondition;
            let queryValue;
            if (this.isClient) {
              queryfield = "client.id";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            } else if (this.isStore) {
              queryfield = "consumer.storeId";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            } else if (this.isSalesRep) {
              queryfield = "salesRep.id";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            }

            orderQuery = query(
              orderRef,
              where(queryfield, queryCondition, queryValue),
              orderBy("createdAt", "desc"),
              endAt(0),
              limit(this.pagination.perPage)
            );
          }

          const unsub = await onSnapshot(
            orderQuery,
            (querySnapshot) => {
              this.orders = querySnapshot.docs.map((doc) =>
                Object.assign({ id: doc.id }, doc.data())
              );
              this.tableData = this.orders;
            },
            (err) => {
              console.debug(err);
            }
          );
        } catch (error) {
          console.debug("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      }

      // else if (action == "per-page") {
      //   let firstDoc = this.tableData[0];
      //   firstDoc = await this.productsRef.doc(firstDoc.id).get();
      //   const querySnapshot = await this.productsRef
      //     .orderBy("createdAt", "desc") // Change to your sorting criteria
      //     .startAt(firstDoc)
      //     .limit(this.pagination.perPage)
      //     .get();

      //   this.tableData = querySnapshot.docs.map((doc) =>
      //     Object.assign({ id: doc.id }, doc.data())
      //   );
      // }
    },

    async fetchNextData() {
      const orderRef = collection(db, "orders");
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await getDoc(doc(orderRef, lastDoc.id));
      this.tableData = [];
      try {
        const orderRef = collection(db, "orders");
        let orderQuery;
        if (this.isSuperAdmin || this.isEmployee) {
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(this.pagination.perPage)
          );
        } else {
          let queryfield;
          let queryCondition;
          let queryValue;
          if (this.isClient) {
            queryfield = "client.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isStore) {
            queryfield = "consumer.storeId";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isSalesRep) {
            queryfield = "salesRep.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          }

          orderQuery = query(
            orderRef,
            where(queryfield, queryCondition, queryValue),
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(this.pagination.perPage)
          );
        }

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    async fetchPreviousData() {
      const orderRef = collection(db, "orders");
      let firstDoc = this.tableData[0];
      firstDoc = await getDoc(doc(orderRef, firstDoc.id));
      this.tableData = [];
      try {
        let orderQuery;
        if (this.isSuperAdmin || this.isEmployee) {
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "asc"),
            startAfter(firstDoc)
          );
        } else {
          let queryfield;
          let queryCondition;
          let queryValue;
          if (this.isClient) {
            queryfield = "client.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isStore) {
            queryfield = "consumer.storeId";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isSalesRep) {
            queryfield = "salesRep.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          }

          orderQuery = query(
            orderRef,
            where(queryfield, queryCondition, queryValue),
            orderBy("createdAt", "asc"),
            limit(this.pagination.perPage)
          );
        }

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped>
.hidden-containers {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

/* .table-row {
  cursor: pointer;
} */

.search-bar {
  position: relative;
  width: 535px !important;
  top: 10px;
  left: 10px;
  display: flex;
}

.pagination-wrapper {
  padding: 20px;
}

.color-key-container {
  width: 258px;
  position: relative;
}

.color-key-dropdown {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 24px;
  z-index: 999;
}

.color-status {
  width: 100%;
  text-align: center;
}

.color-status:hover {
  transform: scale(1.2);
}

.act-icon:hover {
  transform: scale(1.3);
}
</style>
