<template>
  <div v-if="order.consumer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            Order Details
          </p>
        </li>

        <li class="nav-item">
          <p
            @click="selectedTab = 'payment'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'payment' }"
          >
            Payment Overview
          </p>
        </li>

        <li class="nav-item">
          <p
            @click="selectedTab = 'notes'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'notes' }"
          >
            <soft-badge
              v-if="order.hasActiveNotes"
              className="order-detail-badge"
              color="success"
              >!</soft-badge
            >
            Notes
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div class="info-container">
                <p class="text-sm mb-0">
                  Order no.
                  <b>{{ order.orderNumber }}</b> from Store #
                  <b @click="goToStore">{{ order.consumer.storeId }}</b>
                </p>
                <p class="text-sm mb-0">
                  Created On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <p class="text-sm">
                  Reference #:
                  <b> {{ order.client.refNum }}</b>
                </p>
                <section>
                  <div class="order-ids">
                    <p>
                      Collection: <b>{{ order.collection.title }} </b>
                    </p>
                    <p>
                      Purchase Order #: <b> {{ order.purchaseOrderNumber }}</b>
                    </p>
                    <p>
                      Order #: <b>{{ order.orderNumber }} </b>
                    </p>
                    <p>
                      Date: <b> {{ format_date(order.createdAt) }}</b>
                    </p>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div>Thumbnail</div>
                          <sub>Click the image to view</sub>
                        </th>
                        <th>Order #</th>
                        <th>Item(s) Ordered</th>
                        <th>SKU</th>
                        <th>Quality</th>
                        <th v-if="order.engravings || order.birthStones.length">
                          Customization
                        </th>
                        <th>Quantity</th>
                        <th>Store's Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="images">
                            <div v-if="displayedImage">
                              <img
                                @click="enlargeImage(displayedImage)"
                                v-if="displayedImage.url"
                                class="image"
                                :src="displayedImage.url"
                                alt=""
                                srcset=""
                              />
                            </div>
                          </div>
                        </td>
                        <td>{{ order.orderNumber }}</td>
                        <td>
                          {{
                            `${order.origin} ${
                              order.jewelryType == "Ring"
                                ? `Size ${order.size}`
                                : ""
                            },  ${order.caratWeight}ct t.w ${order.shape} ${
                              order.metal.name
                            } ${order.style} ${order.jewelryType}`
                          }}
                        </td>
                        <td>{{ order.sku }}</td>
                        <td>
                          <div>{{ order.origin }}</div>
                          <div>{{ order.quality }}</div>
                        </td>
                        <td v-if="order.engravings || order.birthStones.length">
                          <table>
                            <thead>
                              <tr>
                                <th>Engravings</th>
                                <th>Birthstones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td v-if="order.engravings">
                                  <div class="engravings">
                                    <!-- <p>Font: {{ order.engravings.font }}</p> -->
                                    <p
                                      :style="{
                                        'font-family': order.engravings.font,
                                        'font-style': 'italic',
                                        'text-align': 'center',
                                      }"
                                    >
                                      {{ order.engravings.text }}
                                    </p>
                                  </div>
                                </td>
                                <td v-if="order.birthStones.length">
                                  <div class="birthstones">
                                    <p
                                      v-for="stone in order.birthStones"
                                      :key="stone.name"
                                    >
                                      {{ stone.name }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ order.quantity }}</td>
                        <td>
                          {{
                            format_price(order.priceBreakdown.storeTotalCost)
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr
                        v-if="
                          order.paymentType == 'PayPal' ||
                          order.paymentType == 'Credit Card'
                        "
                      >
                        <td
                          style="text-align: left !important"
                          :colspan="
                            order.engravings || order.birthStones.length ? 7 : 6
                          "
                        >
                          Luna's
                          {{ `${order.paymentType == "PayPal" ? "4%" : "3%"}` }}
                          Transaction Fee
                        </td>
                        <td>
                          {{
                            format_price(
                              order.priceBreakdown[
                                paymentTypeFees[order.paymentType]
                              ]
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="text-align: left !important"
                          :colspan="
                            order.engravings || order.birthStones.length ? 7 : 6
                          "
                        >
                          Total
                        </td>
                        <td>{{ format_price(order.totalCost) }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </section>

                <image-modal
                  :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
                  :url="selectedImage"
                  type="image"
                />
              </div>
            </div>

            <button
              @click="exportOrder('pdf')"
              type="button"
              class="btn btn-success"
              color="dark"
              variant="outline"
              aria-expanded="false"
              id="export-btn"
            >
              Print Order Receipt
            </button>
          </div>
          <div v-if="selectedTab == 'payment'" class="card-body p-3 pt-0 row">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="col-lg-3 col-md-6 col-12">
              <div class="form">
                <div class="form-group">
                  <label for="">Payment Status</label>
                  <select
                    @change="handlePaymentStatus"
                    :disabled="
                      (!$store.state.authModule.isSuperAdmin &&
                        !$store.state.authModule.isEmployee) ||
                      order.paymentStatus.toUpperCase() === 'PAID'
                    "
                    id="choices-category-edit"
                    v-model="order.paymentStatus"
                    class="form-control"
                    name="choices-category"
                  >
                    <option
                      v-for="status in statuses"
                      :key="status"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                  <div
                    v-if="
                      order.paymentStatus === 'In Process' &&
                      order.paymentType === 'Check'
                    "
                    class="check-view"
                  >
                    <soft-button
                      :disabled="order.paymentStatus === 'Paid'"
                      color="info"
                      @click="handleCheckView"
                      >View Check</soft-button
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Payment Type</label>
                  <select
                    @change="handlePaymentTypeStatus"
                    :disabled="
                      order.paymentType !== 'System Order - Client Must Pay'
                    "
                    id="choices-category-edit"
                    v-model="order.paymentType"
                    class="form-control"
                    name="choices-category"
                  >
                    <option
                      v-for="status in [
                        'Check',
                        'Credit Card',
                        'System Order - Client Must Pay',
                      ]"
                      :key="status"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
              </div>

              <ClientInvoice :order="order" />
            </div>
          </div>
          <div v-if="selectedTab == 'notes'" class="card-body p-5 pt-0 row">
            <Notes collection="orders" :dataId="order.id"></Notes>
          </div>
        </div>
      </div>
    </div>

    <modal
      id="payment-modal"
      :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
      @close="closeModal"
    >
      <template v-slot:body>
        <div v-if="!authenticated" class="auth-wrapper">
          <label for="">Please Scan or Enter Your Authentification Code</label>
          <input @change="handleAuthCode" type="text" name="" id="" />
          <div v-if="authenticating" class="loading">
            <i style="font-size: 80px" class="fas fa-spinner fa-spin fa-lg"></i>
            <br />
            <p>Authenticating ...</p>
          </div>
        </div>
        <div v-else>
          <div class="check-image-container">
            <div class="check-image-wrapper">
              <label for="">Front</label>
              <img class="check-image" :src="checkImage.front" alt="" />
            </div>

            <div v-if="checkImage.back" class="check-image-wrapper">
              <label for="">Back</label>
              <img class="check-image" :src="checkImage.back" alt="" />
            </div>
          </div>
          <div class="order-list">
            <h5>This Check is linked to these orders:</h5>
            <ul>
              <li v-for="linkedOrder in allOrdersLinked" :key="linkedOrder.id">
                {{ linkedOrder.id }} - {{ format_price(linkedOrder.total) }}
              </li>
            </ul>

            <strong>Total: {{ format_price(totalTally) }}</strong>
          </div>
        </div>
      </template>
      <template v-if="authenticated" v-slot:footer>
        <soft-button
          color="info"
          class="btn btn-secondary"
          @click="downloadImage"
          >Download Image</soft-button
        >
      </template>
    </modal>
    <OrderReceipt
      class="order-receipt"
      ref="order_receipt_container"
      :order="order"
    ></OrderReceipt>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";
import html2canvas from "html2canvas";
import Modal from "./components/PaymentModal.vue";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import OrderReceipt from "@/components/OrderReceipt.vue";
import CryptoJS from "crypto-js";
import Notes from "@/components/Notes.vue";
import ClientInvoice from "../ecommerce/Orders/ClientInvoice.vue";
import axios from "axios";
import {
  collection,
  doc,
  db,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc,
  onSnapshot,
  deleteDoc,
  functions,
  httpsCallable,
} from "@/config/firebaseInit.js";
export default {
  name: "ShippingDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftInput,
    SoftCheckbox,
    ImageModal,
    ClientInvoice,
    Modal,
    Notes,
    OrderReceipt,
  },
  props: {
    order: {
      type: Object,
      default() {
        return Object;
      },
    },
  },

  data() {
    return {
      selectedTab: "details",
      paymentTypeFees: {
        "Credit Card": "ccFee",
        Check: "achFee",
        ACH: "achFee",
        PayPal: "PayPal",
      },
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      showExportOptions: false,
      isUsingStorageToFetchCheckImage: false,
      selectedImage: null,
      displayedImage: null,
      notes: [],
      statuses: ["Paid", "Not Paid", "Rejected", "In Process"],
      checkImage: {},
      checkImagePath: {},
      authenticated: false,
      authenticating: false,
      allOrdersLinked: [],
      note: {
        createdAt: null,
        createdBy: null,
        text: null,
        assignedUsers: [],
      },
      bootstrapModal: null,
    };
  },
  computed: {
    totalTally() {
      return this.allOrdersLinked.reduce((acc, order) => acc + order.total, 0);
    },
  },
  watch: {
    async order(e) {
      if (e) {
        const image = this.order.images.filter((i) => i.type.includes("image"));
        this.displayedImage = image[0] || null;
        this.order.productImageDataUrl = await this.fetchProductImage(
          this.order
        );

        if (this.order.clientACHPaymentData) this.fetchAllOrdersLinkedToCheck();
      }
    },
  },
  methods: {
    async downloadImage() {
      for (const key in this.checkImage) {
        if (Object.prototype.hasOwnProperty.call(this.checkImage, key)) {
          const image = this.checkImage[key];
          try {
            const link = document.createElement("a");
            link.href = image;
            link.download = `${this.order.client.company.toUpperCase()} CHECK ${key.toUpperCase()} SIDE`;
            link.click();
            URL.revokeObjectURL(link.href);
            this.$swal.fire(
              "Please come back to this window once check has been deposited to change payment status to Paid. Be advised once payment status has been changed or a day has passed by since the inital download, this image will be deleted automatically for security reasons"
            );
          } catch (error) {
            console.debug("Error downloading the encrypted image:", error);
          }
        }
      }

      if (this.isUsingStorageToFetchCheckImage) {
        await axios.post(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/deleteFileFromStorageBucket`,
          this.checkImagePath.front
        );

        await axios.post(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/deleteFileFromStorageBucket`,
          this.checkImagePath.back
        );
      } else {
        try {
          const frontEncryptedDataRef =
            this.order.clientACHPaymentData.frontCheckRef ||
            doc(
              db,
              "encrypted_payment",
              this.order.clientACHPaymentData.checkId + "-front"
            );

          const backEncryptedDataRef =
            this.order.clientACHPaymentData.backCheckRef ||
            doc(
              db,
              "encrypted_payment",
              this.order.clientACHPaymentData.checkId + "-back"
            );

          updateDoc(frontEncryptedDataRef, {
            completed: true,
            completedDate: Date.now(),
          });

          updateDoc(backEncryptedDataRef, {
            completed: true,
            completedDate: Date.now(),
          });
        } catch (error) {
          console.debug({ error });
        }

        this.bootstrapModal.hide();
      }
    },
    async fetchAllOrdersLinkedToCheck() {
      if (!this.order.clientACHPaymentData.frontCheckRef) return;
      const ordersRef = collection(db, "orders");
      let orderQuery = query(
        ordersRef,
        where(
          "clientACHPaymentData.checkId",
          "==",
          this.order.clientACHPaymentData.checkId
        )
      );

      const correspondingOrders = await getDocs(orderQuery);

      this.allOrdersLinked = correspondingOrders.docs.map((o) =>
        Object.assign({
          id: o.id,
          total: o.data().priceBreakdown.storeTotalCost,
        })
      );
    },
    async handlePaymentStatus(e) {
      updateDoc(doc(db, "orders", this.order.orderNumber), {
        paymentStatus: e.target.value,
      });

      if (e.target.value === "Paid") {
        if (this.allOrdersLinked.length) {
          await deleteDoc(this.order.clientACHPaymentData.frontCheckRef);
          await deleteDoc(this.order.clientACHPaymentData.backCheckRef);
          this.allOrdersLinked.forEach((order) => {
            updateDoc(doc(db, "orders", order.id), {
              paymentStatus: "Paid",
            });
          });
        }
      }
    },
    async handlePaymentTypeStatus(e) {
      updateDoc(doc(db, "orders", this.order.orderNumber), {
        paymentType: e.target.value,
      });
    },
    handleCheckView() {
      const modalElement = document.getElementById("payment-modal");
      this.bootstrapModal = new bootstrap.Modal(modalElement, {
        backdrop: false,
      });
      this.bootstrapModal.show();
    },
    closeModal() {
      this.bootstrapModal.hide();
      this.authenticated = false;
    },
    async handleAuthCode(e) {
      this.authenticating = true;
      const authenticateAccessCode = httpsCallable(
        functions,
        "authenticateAccessCode"
      );

      const code = e.target.value;
      const res = await authenticateAccessCode({ code });

      if (res.data.authenticated == true) {
        this.authenticating = false;
        this.decryptCheck();
      } else {
        this.authenticating = false;
        this.$swal({
          icon: "error",
          title: "Cannot Be Authenticated",
          text: "User did not implement correct Auth Code. Please contact support",
          type: "error",
        });
      }
    },
    async decryptCheck() {
      const frontEncryptedDataRef =
        this.order.clientACHPaymentData.frontCheckRef ||
        doc(
          db,
          "encrypted_payment",
          this.order.clientACHPaymentData.checkId + "-front"
        );

      const backEncryptedDataRef =
        this.order.clientACHPaymentData.backCheckRef ||
        doc(
          db,
          "encrypted_payment",
          this.order.clientACHPaymentData.checkId + "-back"
        );

      let frontEcryptedData = await getDoc(frontEncryptedDataRef);
      let backEncryptedData = await getDoc(backEncryptedDataRef);
      let encryptedFront;
      let encryptedBack;

      if (frontEcryptedData.exists() && backEncryptedData.exists()) {
        encryptedFront = JSON.parse(frontEcryptedData.data().data);
        encryptedBack = JSON.parse(backEncryptedData.data().data);
        encryptedFront = this.decryptImage(
          encryptedFront.encryptedData,
          encryptedFront.key,
          encryptedFront.iv
        );

        if (encryptedBack) {
          encryptedBack = this.decryptImage(
            encryptedBack.encryptedData,
            encryptedBack.key,
            encryptedBack.iv
          );
        }

        this.checkImage = {
          front: encryptedFront,
          back: encryptedBack,
        };

        this.authenticated = true;

        return;
      } else {
        if (!this.order.paymentDataEmailSent && this.order.paymentDataStorage) {
          encryptedFront = this.order.paymentDataStorage.front;
          encryptedBack = this.order.paymentDataStorage.back;

          this.checkImage = {
            front: encryptedFront.publicUrl,
            back: encryptedBack.publicUrl,
          };

          this.checkImagePath = {
            front: encryptedFront.filePath,
            back: encryptedBack.filePath,
          };

          this.authenticated = true;
          this.isUsingStorageToFetchCheckImage = true;

          return;
        } else if (this.order.paymentDataEmailSent) {
          this.$swal.fire(
            "The system indicates that an email containing the client's check has been sent to the Accountant Administrative team. Please check your inbox. If the email has not yet arrived, allow a few moments for delivery. If it still has not been received after some time, please contact technical support for assistance."
          );
        } else {
          this.$swal({
            icon: "error",
            title: "Check cannot be retrieved",
            text: "There appears to be an issue, as no check is currently available. Please check your email or reach out to the client directly. Technical support has been notified and is looking into the matter.",
            type: "error",
          });
        }
      }
    },
    decryptImage(encryptedData, key, iv) {
      // Parse Base64 encoded key and IV
      const keyBytes = CryptoJS.enc.Base64.parse(key);
      const ivBytes = CryptoJS.enc.Base64.parse(iv);

      // Decrypt the encrypted image
      const decrypted = CryptoJS.AES.decrypt(encryptedData, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convert decrypted data to Base64 string
      const decryptedMedia = CryptoJS.enc.Base64.stringify(decrypted);

      return `data:image/png;base64,${decryptedMedia}`;
    },
    async fetchProductImage(order) {
      try {
        const image = order.images.filter((i) => i.type.includes("image"));
        const displayedImage = image[0] || null;
        return await this.getBase64ImageFromUrl(displayedImage.url);
      } catch (error) {
        console.debug("Error fetching product image:", error);
      }
    },
    getBase64ImageFromUrl(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = function () {
          reject("Error loading image");
        };
        img.src = url;
      });
    },
    exportOrder(option) {
      switch (option) {
        case "pdf":
          this.exportToPDF();
          break;

        case "csv":
          this.exportToExcel();
          break;
      }
    },
    async exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      // Example data
      const data = [
        [
          "Order Number",
          "PO Number",
          "Jewelry Details",
          "Engraving",
          "Birthstones",
        ],
        [
          this.order.orderNumber,
          this.order.purchaseOrderNumber,
          `Name: ${this.order.style}\nShape: ${
            this.shapeType[this.order.shapeType]
          }\nCarat: ${this.addCTTW(this.order)} \nMetal: ${
            this.metals[this.order.metal]
          } \nSize: ${this.order.ringSize}`,
          `Message: ${
            this.order.engraving ? this.order.engraving.text : "None"
          }\nFont: ${
            this.order.engraving ? this.order.engraving.font : "None"
          }`,
          `${
            this.order.birthStones.length
              ? this.order.birthStones.map((s) => s.name).join("\n")
              : "None"
          } `,
        ],
      ];

      // Add data to the worksheet
      data.forEach((row) => {
        const excelRow = worksheet.addRow(row);

        // Apply text wrap and center alignment
        excelRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "justify",
            horizontal: "left",
            wrapText: true,
          };
        });
      });

      worksheet.columns.forEach((column) => {
        column.width = 45; // Adjust the width as needed
      });

      // Set response headers for download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.order.purchaseOrderNumber}.xlsx`;
      link.click();
    },
    async exportToPDF() {
      const order_container_ref = this.$refs.order_receipt_container;
      const pdf = new jsPDF();
      const content = order_container_ref.$refs.contentToExport; // Replace with your element's ref or selector
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 10, 200, 200);
      pdf.save(`${this.order.purchaseOrderNumber}.pdf`);
    },

    enlargeImage(img) {
      this.selectedImage = img.url;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    addCTTW(order) {
      const cctw = order.sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
  },
};
</script>

<style scoped>
/* IDS */

#order-detail-export-dropdown {
  margin-top: -11px !important;
  /* margin-left: -144px !important; */
  opacity: 1 !important;
  pointer-events: auto !important;
}
#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */

.auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.check-image-wrapper {
  max-width: 350px;
}

.check-image {
  width: 100%;
}

.order-receipt {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.note-label {
  display: flex;
}
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.info-container {
  width: 100%;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
  margin-bottom: 30px;
}
.form {
  display: flex;
}
.form-group {
  width: 250px;
  margin-right: 50px;
}
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.check-view {
  margin-top: 25px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 150px;
  cursor: zoom-in;
}
.images {
  display: flex;
  width: 145px;
  justify-content: center;
}

.checkbox-wrapper {
  display: flex;
  gap: 11px;
}

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}
</style>
