<template>
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
          @click="handleAccordion('collapseOne')"
        >
          <div class="btn-content">
            <span>Credit/Debit </span
            ><i
              :class="{
                'icon-rotated-up': selected == 'collapseOne',
                'icon-rotated-down': selected != 'collapseOne',
              }"
              class="fas fa-chevron-down"
            ></i>
          </div>
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <CreditCard
            v-if="selected == 'collapseOne'"
            @finalize-order="finalizeOrder"
            :clientToken="clientToken"
            :selectedOrder="selectedOrder"
            :client="client"
            :multiplePayments="multiplePayments"
            :selectedOrders="selectedOrders"
          ></CreditCard>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
          @click="handleAccordion('collapseTwo')"
        >
          <div class="btn-content">
            <span>Wire Transfer / ACH Payment Information</span
            ><i
              :class="{
                'icon-rotated-up': selected == 'collapseTwo',
                'icon-rotated-down': selected != 'collapseTwo',
              }"
              class="fas fa-chevron-down"
            ></i>
          </div>
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <check-upload
            v-if="selected == 'collapseTwo'"
            @finalize-order="finalizeOrder"
            :clientToken="clientToken"
            :selectedOrder="selectedOrder"
            :client="client"
            :multiplePayments="multiplePayments"
            :selectedOrders="selectedOrders"
          ></check-upload>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
          @click="handleAccordion('collapseThree')"
        >
          <div class="btn-content">
            <span>PayPal</span
            ><i
              :class="{
                'icon-rotated-up': selected == 'collapseThree',
                'icon-rotated-down': selected != 'collapseThree',
              }"
              class="fas fa-chevron-down"
            ></i>
          </div>
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <PayPalDropIn
            @finalize-order="finalizeOrder"
            :clientToken="clientToken"
            :client="client"
            :selectedOrder="selectedOrder"
            :multiplePayments="multiplePayments"
            :selectedOrders="selectedOrders"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckUpload from "./components/CheckUpload.vue";
import CreditCard from "./components/CreditCard.vue";
import PayPalDropIn from "./components/PayPalDropIn.vue";
import axios from "axios";
import { doc, db, updateDoc, setDoc } from "@/config/firebaseInit.js";

export default {
  components: {
    CheckUpload,
    CreditCard,
    PayPalDropIn,
  },
  props: {
    selectedOrder: {
      type: Object,
      default: Object,
    },
    selectedOrders: {
      type: Array,
      default: [],
    },
    multiplePayments: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handle-multiple-on-closer"],
  data() {
    return {
      accordion: {},
      selected: null,
      clientToken: null,
      bccEmails: [],
    };
  },
  computed: {
    client() {
      return this.$store.state.authModule.client;
    },
  },
  mounted() {
    this.autorizeBraintreeClient();
  },
  methods: {
    async finalizeOrder(payload) {
      const { paymentType, paymentStatus, fee, type, data, key } = payload;
      if (this.multiplePayments) {
        let frontCheckRef;
        let backCheckRef;
        if (paymentType == "Check") {
          try {
            frontCheckRef = doc(
              db,
              "encrypted_payment",
              data.checkId + "-front"
            );
            backCheckRef = doc(db, "encrypted_payment", data.checkId + "-back");

            await setDoc(frontCheckRef, {
              data: data.media.front,
            });

            await setDoc(backCheckRef, {
              data: data.media.front,
            });
          } catch (error) {
            console.debug("Error Uploading Check: ", error);
          }
        }

        for (let index = 0; index < this.selectedOrders.length; index++) {
          const order = this.selectedOrders[index];
          order.paymentStatus = paymentStatus;
          order.paymentType = paymentType;

          if (paymentType == "Check") {
            if (!order.clientACHPaymentData) {
              order.clientACHPaymentData = {};
            }

            order.clientACHPaymentData.frontCheckRef = frontCheckRef;
            order.clientACHPaymentData.backCheckRef = backCheckRef;
            order.clientACHPaymentData.checkId = data.checkId;
            order.clientACHPaymentData.authorizedConfirmed =
              data.authorizedConfirmed;
          }

          if (fee) {
            order.priceBreakdown[type] = fee;
            order.totalCost = parseFloat((fee + order.totalCost).toFixed(2));
          }

          await setDoc(doc(db, "orders", order.id), order);
        }

        this.$swal.fire("Your invoices has been paid");
        // this.$emit("handle-multiple-on-closer");
      } else {
        this.selectedOrder.paymentStatus = paymentStatus;
        this.selectedOrder.paymentType = paymentType;
        if (paymentType == "Check") {

          try {
            const frontCheckRef = doc(
              db,
              "encrypted_payment",
              data.checkId + "-front"
            );
            const backCheckRef = doc(
              db,
              "encrypted_payment",
              data.checkId + "-back"
            );

            await setDoc(frontCheckRef, {
              data: data.media.front,
            });

            await setDoc(backCheckRef, {
              data: data.media.front,
            });

            if (!this.selectedOrder.clientACHPaymentData) {
              this.selectedOrder.clientACHPaymentData = {};
            }

            this.selectedOrder.clientACHPaymentData.frontCheckRef =
              frontCheckRef;
            this.selectedOrder.clientACHPaymentData.backCheckRef = backCheckRef;
            this.selectedOrder.clientACHPaymentData.checkId = data.checkId;
            this.selectedOrder.clientACHPaymentData.authorizedConfirmed =
              data.authorizedConfirmed;
          } catch (error) {
            console.debug("Error Uploading Check: ", error);
          }
        }

        if (fee) {
          this.selectedOrder.priceBreakdown[type] = fee;
          this.selectedOrder.totalCost = parseFloat(
            (fee + this.selectedOrder.totalCost).toFixed(2)
          );
        }

        await setDoc(
          doc(db, "orders", this.selectedOrder.id),
          this.selectedOrder
        );

        this.$swal.fire("Your invoice has been paid");
      }
    },
    async autorizeBraintreeClient() {
      try {
        const res = await axios.get(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/LunaCollectionServer/client_token`
        );
        this.clientToken = res.data;
      } catch (err) {
        console.debug("PayPortal Component: ", { err });
        this.displayError(err);
      }
    },
    handleAccordion(id) {
      let myCollapse = document.getElementById(id);
      if (this.selected == id) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          this.selected = null;
        }, 500);
      } else {
        this.selected = id;
        // let bsCollapse = new bootstrap.Collapse(myCollapse, {
        //   toggle: true,
        // });
      }
    },
  },
};
</script>

<style scoped>
.icon-rotated-up {
  transition: transform 0.3s ease;
  animation: rotateUp 0.5s forwards;
}

.icon-rotated-down {
  transition: transform 0.3s ease;
  animation: rotateDown 0.5s forwards;
}

@keyframes rotateUp {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateDown {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
.accordion-button {
  border: 0.5px solid rgb(204, 200, 200);
  margin-bottom: 15px;
}

.btn-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
</style>