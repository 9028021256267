<template>
  <div>
    <div style="padding: 20px" class="">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table text-right">
              <thead class="bg-default">
                <tr>
                  <th scope="col" class="pe-2 text-start ps-2">Order #</th>
                  <th scope="col" class="pe-2">Item</th>
                  <th scope="col" class="pe-2">Qty</th>
                  <!-- <th scope="col" class="pe-2" colspan="2">Rate</th> -->
                  <th scope="col" class="pe-2">Amount Due</th>
                </tr>
              </thead>
              <tbody v-if="!multiplePayments">
                <tr>
                  <td class="text-start">{{ order.orderNumber }}</td>
                  <td class="ps-4">{{ order.sku }}</td>
                  <td class="ps-4">{{ order.quantity }}</td>
                  <!-- <td class="ps-4" colspan="2">$ 9.00</td> -->
                  <td class="ps-4">
                    {{ format_price(order.originalPrice) }}
                  </td>
                </tr>
                <tr
                  v-if="
                    order.priceBreakdown &&
                    order.priceBreakdown.travelJewelryCosts
                  "
                >
                  <td class="text-start">Travel {{ order.jewelryType }}</td>
                  <td class="ps-4">1</td>
                  <td class="ps-4">
                    {{ format_price(order.priceBreakdown.travelJewelryCosts) }}
                  </td>
                </tr>
                <tr v-if="order.engravings">
                  <td class="text-start">
                    <div>Engravings</div>
                    <div :style="{ fontFamily: order.engravings.font }">
                      {{ order.engravings.text }}
                    </div>
                  </td>
                  <td class="ps-4">1</td>
                  <td class="ps-4">
                    {{ format_price(50) }}
                  </td>
                </tr>
                <!-- <tr>
                          <td class="text-start">Parts for service</td>
                          <td class="ps-4">1</td>
                          <td class="ps-4" colspan="2">$ 89.00</td>
                          <td class="ps-4">$ 89.00</td>
                        </tr> -->
              </tbody>

              <tbody v-else v-for="o in selectedOrders" :key="o.id">
                <tr>
                  <td class="text-start">{{ o.orderNumber }}</td>
                  <td class="ps-4">{{ o.sku }}</td>
                  <td class="ps-4">{{ o.quantity }}</td>
                  <!-- <td class="ps-4" colspan="2">$ 9.00</td> -->
                  <td class="ps-4">
                    {{ format_price(o.originalPrice) }}
                  </td>
                </tr>
                <tr v-if="o.priceBreakdown.travelJewelryCosts">
                  <td class="text-start">Travel {{ o.jewelryType }}</td>
                  <td class="ps-4">1</td>
                  <td class="ps-4">
                    {{ format_price(o.priceBreakdown.travelJewelryCosts) }}
                  </td>
                </tr>
                <tr v-if="o.engravings">
                  <td class="text-start">
                    <div>Engravings</div>
                    <div :style="{ fontFamily: o.engravings.font }">
                      {{ o.engravings.text }}
                    </div>
                  </td>
                  <td class="ps-4">1</td>
                  <td class="ps-4">
                    {{ format_price(50) }}
                  </td>
                </tr>
                <!-- <tr>
                          <td class="text-start">Parts for service</td>
                          <td class="ps-4">1</td>
                          <td class="ps-4" colspan="2">$ 89.00</td>
                          <td class="ps-4">$ 89.00</td>
                        </tr> -->
              </tbody>
              <tfoot>
                <tr>
                  <th></th>
                  <th class="h5 ps-4" colspan="2">
                    <sub>4% Processing Fee</sub>
                  </th>
                  <th colspan="1" class="text-right h5 ps-2">
                    + {{ format_price(ccFee) }}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th class="h4 ps-4" colspan="2">Total</th>
                  <th colspan="1" class="text-right h5 ps-4">
                    {{ format_price(totalCost) }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div id="paypal-button-container"></div>
    </div>
  </div>
</template>
  
  <script>
import braintree from "braintree-web";
import axios from "axios";

export default {
  props: {
    selectedOrder: {
      type: Object,
      default: Object,
    },
    selectedOrders: {
      type: Array,
      default: [],
    },
    multiplePayments: {
      type: Boolean,
      default: false,
    },
    clientToken: {
      type: String,
      default: "",
    },
    client: {
      type: Object,
      default: Object,
    },
    bccEmails: {
      type: Array,
      default: [],
    },
  },
  emits: ["finalize-order"],
  data() {
    return {
      deviceData: null,
      order: {},
    };
  },
  computed: {
    totalCost() {
      let totalCost = 0;
      if (!this.multiplePayments) {
        totalCost = this.order.originalPrice;
        const travelJewelryCosts =
          (this.order.priceBreakdown &&
            this.order.priceBreakdown.travelJewelryCosts) ||
          0;
        const engravings = this.order.engravings ? 50 : 0;
        totalCost = (totalCost + travelJewelryCosts + engravings) * 1.04;
      } else {
        totalCost = this.selectedOrders.reduce((value, order) => {
          const travelJewelryCosts =
            order.priceBreakdown.travelJewelryCosts || 0;
          const engravings = order.engravings && order.engravings.text ? 50 : 0;
          return order.originalPrice + travelJewelryCosts + engravings + value;
        }, 0);
        totalCost *= 1.04;
      }

      return totalCost;
    },
    ccFee() {
      return (this.totalCost / 1.04) * 0.04;
    },
  },
  watch: {
    clientToken() {
      if(this.$store.state.authModule.isClient) this.initializePayPalButton() 
    },
  },
  created() {
    if (!this.multiplePayments)
      this.order = JSON.parse(JSON.stringify(this.selectedOrder));
    else this.showView = true;
  },

  methods: {
    // addCCFee() {
    //   this.ccFee = this.order.originalPrice * 0.03;
    //   this.order.priceBreakdown.storeTotalCost += this.ccFee;
    // },
    initializePayPalButton() {
      braintree.client.create(
        {
          authorization: this.clientToken,
        },
        (err, clientInstance) => {
          if (err) {
            console.error(err);
            return;
          }

          // Create Data Collector for fraud detection
          braintree.dataCollector.create(
            {
              client: clientInstance,
            },
            (err, dataCollectorInstance) => {
              if (err) {
                console.error(err);
                return;
              }
              this.deviceData = dataCollectorInstance.deviceData;
            }
          );

          // Create PayPal Checkout Instance
          braintree.paypalCheckout.create(
            {
              client: clientInstance,
            },
            (err, paypalCheckoutInstance) => {
              if (err) {
                console.error(err);
                return;
              }

              // Use PayPal buttons with Braintree integration
              paypalCheckoutInstance.loadPayPalSDK(
                {
                  currency: "USD",
                  intent: "capture", // or 'capture' based on your payment flow
                  "disable-funding": "card,credit,paylater",
                },
                () => {
                  paypal
                    .Buttons({
                      createOrder: (data, actions) => {
                        return paypalCheckoutInstance.createPayment({
                          flow: "checkout",
                          currency: "USD",
                          amount: this.totalCost.toFixed(2),
                          intent: "capture",
                        });
                      },
                      onApprove: (data, actions) => {
                        return paypalCheckoutInstance.tokenizePayment(
                          data,
                          (err, payload) => {
                            if (err) {
                              console.debug(err);
                              return;
                            }
                            this.submitPayment(payload.nonce, this.deviceData);
                          }
                        );
                      },
                      onError: (err) => {
                        console.debug("PayPal button error:", err);
                        // this.displayError(err);
                      },
                    })
                    .render("#paypal-button-container");
                }
              );
            }
          );
        }
      );
    },
    submitPayment(nonce, deviceData) {
      return axios
        .post(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/LunaCollectionServer/checkout`,
          {
            nonceFromTheClient: nonce,
            totalAmount: parseFloat(this.totalCost.toFixed(2)),
            // totalAmount: 1,
            deviceDataFromTheClient: deviceData,
            customerId: this.client.braintree_customer_id || "",
          }
        )
        .then((response) => {
          return this.$emit("finalize-order", {
            paymentType: "PayPal",
            paymentStatus: "Paid",
            fee: this.multiplePayments
              ? this.ccFee / this.selectedOrders.length
              : this.ccFee,
            type: "PayPal",
          });
        })
        .catch((error) => {
          this.$swal.fire(`Error: ${error}`);
          console.debug("Error:", error);
        });
    },
  },
};
</script>
  
  <style scoped>
#paypal-button-container {
  margin-top: 20px;
  width: 700px;
}

.cancel-btn {
  margin-bottom: 8%;
  opacity: 0.4;
  text-decoration: underline;
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
  