<template>
  <div v-if="order.consumer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            Order Details
          </p>
        </li>

        <li class="nav-item">
          <p
            @click="selectedTab = 'notes'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'notes' }"
          >
            Notes
            <soft-badge
              v-if="order.hasActiveNotes"
              className="order-detail-badge"
              color="success"
              >!</soft-badge
            >
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div class="info-container">
                <p class="text-sm mb-0">
                  Order Placed On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <br />
                <section>
                  <div class="order-ids">
                    <p>
                      Purchase Order #: <b> {{ order.purchaseOrderNumber }}</b>
                    </p>
                    <p>
                      Order #: <b>{{ order.orderNumber }} </b>
                    </p>

                    <div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          gap: 5px;
                          align-items: center;
                        "
                      >
                        Metal Price:
                        <div
                          @click="showHelper = !showHelper"
                          class="helper-icon"
                        >
                          ?
                        </div>
                      </div>
                      <div v-if="showHelper" class="helper">
                        According To The Metal Exchange, price was retrieved
                        from this
                        <a
                          style="color: blue; text-decoration: underline"
                          href="https://www.goldapi.io/"
                          >API.</a
                        >
                        which uses this
                        <a
                          style="color: blue; text-decoration: underline"
                          href="https://www.forex.com/en/gold-silver-trading/xau-usd/"
                          >Source</a
                        >
                        at the time of purchase.
                      </div>
                      <b style="color: red">{{
                        format_price(order.gold_metal_price_rates.price)
                      }}</b>
                    </div>
                    <div>
                      Metal Rates:
                      <p style="color: red">
                        <b
                          >{{
                            order.gold_metal_price_rates.metal_rates
                              .price_gram_14k
                          }}
                          <sub>on 14K</sub></b
                        >
                      </p>
                      <p style="color: red">
                        <b
                          v-if="
                            order.gold_metal_price_rates.metal_rates
                              .price_gram_18k
                          "
                          >{{
                            order.gold_metal_price_rates.metal_rates
                              .price_gram_18k
                          }}<sub>on 18K</sub></b
                        >
                      </p>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div>Thumbnail</div>
                          <sub>Click the image to view</sub>
                        </th>
                        <th>Order #</th>
                        <th>Item(s) Ordered</th>
                        <th
                          :style="{
                            color: order.sku ? 'var(--bs-body-color)' : 'red',
                          }"
                        >
                          SKU
                        </th>
                        <th v-if="order.jewelryType != 'Earring'">Size</th>
                        <th>Carat Weight</th>
                        <th>Metal</th>
                        <th>Shape</th>
                        <th>Quality</th>
                        <th v-if="order.engravings || order.birthStones.length">
                          Customization
                        </th>
                        <!-- <th>Price</th> -->
                        <th>Quantity</th>
                        <th>Trezza's Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="images">
                            <div v-if="displayedImage">
                              <img
                                @click="enlargeImage(displayedImage)"
                                v-if="displayedImage.url"
                                class="image"
                                :src="displayedImage.url"
                                alt=""
                                srcset=""
                              />
                            </div>
                          </div>
                        </td>
                        <td>{{ order.orderNumber }}</td>
                        <td>
                          {{
                            `${order.origin} ${
                              order.jewelryType == "Ring"
                                ? `Size ${order.size}`
                                : ""
                            },  ${order.caratWeight}ct t.w ${order.shape} ${
                              order.metal.name
                            } ${order.style} ${order.jewelryType}`
                          }}
                        </td>
                        <td>{{ order.sku }}</td>
                        <td v-if="order.jewelryType != 'Earring'">
                          {{ order.size }}
                        </td>
                        <td>{{ `${order.caratWeight}ct t.w` }}</td>
                        <td>{{ order.metal.name }}</td>
                        <td>{{ order.shape }}</td>
                        <td>
                          <div>{{ order.origin }}</div>
                          <div>{{ order.quality }}</div>
                        </td>
                        <td v-if="order.engravings || order.birthStones.length">
                          <table>
                            <thead>
                              <tr>
                                <th>Engravings</th>
                                <th>Birthstones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td v-if="order.engravings">
                                  <div class="engravings">
                                    <!-- <p>Font: {{ order.engravings.font }}</p> -->
                                    <p
                                      :style="{
                                        'font-family': order.engravings.font,
                                        'font-style': 'italic',
                                        'text-align': 'center',
                                      }"
                                    >
                                      {{ order.engravings.text }}
                                    </p>
                                  </div>
                                </td>
                                <td v-if="order.birthStones.length">
                                  <div class="birthstones">
                                    <p
                                      v-for="stone in order.birthStones"
                                      :key="stone.name"
                                    >
                                      {{ stone.name }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ order.quantity }}</td>
                        <td style="color: red">
                          {{
                            format_price(
                              calculateCurrentTrezzaPrice(
                                order.originalPrice,
                                order.origin
                              )
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          style="text-align: left !important"
                          :colspan="
                            order.engravings || order.birthStones.length
                              ? 12
                              : 11
                          "
                        >
                          Important Notice:

                          <p
                            style="color: red; font-size: 15px"
                            v-if="!order.sku"
                          >
                            This is a special order. Please enter the SKU for
                            this item before continuing.
                          </p>

                          <p style="color: red; font-size: 15px">
                            Cannot Exceed FOB Price Listed Above. This price is
                            estimated according to the metal rate on the time
                            the order was placed.
                          </p>
                          <p>
                            According To The Metal Exchange, price was retrieved
                            from this
                            <a
                              style="color: blue; text-decoration: underline"
                              href="https://www.goldapi.io/"
                              >API.</a
                            >
                            which uses this
                            <a
                              style="color: blue; text-decoration: underline"
                              href="https://www.forex.com/en/gold-silver-trading/xau-usd/"
                              >Source</a
                            >.
                          </p>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </section>

                <image-modal
                  :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
                  :url="selectedImage"
                  type="image"
                />
              </div>
            </div>
            <div class="status-wrapper">
              <div
                v-if="!order.status.includes('Consumer')"
                class="d-flex align-items-center"
              >
                <div class="form">
                  <div class="form-group">
                    <h5 for="">Order Status</h5>
                    <select
                      :disabled="
                        order.status == 'Completed' || order.shippedToDIS
                      "
                      id="choices-category-edit"
                      v-model="order.status"
                      class="form-control"
                      name="choices-category"
                      @change="$emit('handle-input', $event, 'status')"
                    >
                      <option disabled value="Order Created">
                        Order Created
                      </option>
                      <option disabled value="In Process">In Process</option>
                      <option
                        :disabled="order.status == 'Order Created'"
                        value="Delayed"
                      >
                        Delayed
                      </option>
                      <option
                        :disabled="order.status == 'Order Created'"
                        value="Shipped To DIS"
                      >
                        Shipped To DIS
                      </option>
                      <option v-if="order.shippedToDIS">
                        {{ order.status }}
                      </option>
                    </select>
                  </div>

                  <div
                    v-if="
                      !order.status.includes('Shipped') &&
                      order.status.includes('In Process')
                    "
                    class="form-group"
                  >
                    <label for="">
                      <div>ORDER DEADLINE</div>
                    </label>
                    <div class="form-control">
                      {{ format_date(order.completionDeadline) }}
                    </div>
                    <sub style="color: red">Must complete this order by:</sub>
                  </div>

                  <div v-if="order.status == 'Delayed'" class="form-group">
                    <label for=""> Enter Delayed Date </label>
                    <div class="deadline-date">
                      <input
                        v-model="order.delayedDate"
                        @change="$emit('handle-input', $event, 'delayedDate')"
                        class="form-control"
                        type="date"
                        name=""
                        id=""
                      />
                    </div>
                  </div>

                  <div
                    class="shipping-info"
                    v-if="order.status.includes('Shipped')"
                  >
                    <div class="form-group">
                      <label for=""
                        >Shipping Carrier <span class="required">*</span></label
                      >
                      <select
                        id="choices-category-edit"
                        v-model="order.shippingDetails.carrier"
                        class="form-control"
                        name="choices-category"
                        @change="
                          $emit(
                            'handle-input',
                            $event,
                            'shippingDetails',
                            'carrier'
                          )
                        "
                      >
                        <option value="UPS">UPS</option>
                        <option value="Fedex">Fedex</option>
                        <option value="Sequel">Sequel</option>
                        <option value="Loomis">Loomis International</option>
                      </select>
                    </div>
                    <div
                      v-if="order.shippingDetails.carrier != 'Loomis'"
                      class="form-group"
                    >
                      <label for=""
                        >Set Tracking Number
                        <span class="required">*</span></label
                      >
                      <SoftInput
                        @change="
                          $emit(
                            'handle-input',
                            $event,
                            'shippingDetails',
                            'trackingNumber'
                          )
                        "
                        :value="order.shippingDetails.trackingNumber"
                        type="text"
                        placeholder="Set tracking #"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style="color: green" class="center" v-else>
                <strong>Status:</strong> {{ order.status }}
              </div>
            </div>
            <button
              @click="exportOrder('pdf')"
              type="button"
              class="btn btn-success"
              color="dark"
              variant="outline"
              aria-expanded="false"
              :disabled="!order.sku"
              id="export-btn"
            >
              Print Confirmation Page
            </button>
          </div>

          <div v-if="selectedTab == 'notes'" class="card-body p-5 pt-0 row">
            <Notes collection="orders" :dataId="order.id"></Notes>
          </div>
        </div>
      </div>
    </div>

    <OrderReceipt
      class="order-receipt"
      ref="order_receipt_container"
      :order="order"
    ></OrderReceipt>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import OrderReceipt from "./components/OrderReceipt.vue";
import Notes from "@/components/Notes.vue";

import { doc, db, updateDoc } from "@/config/firebaseInit.js";
export default {
  name: "ShippingDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftInput,
    SoftCheckbox,
    ImageModal,
    Notes,
    OrderReceipt,
  },
  props: {
    selectedOrder: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedTab: "details",
      order: {},
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      showExportOptions: false,
      showHelper: false,
      selectedImage: null,
      displayedImage: null,
      notes: [],
      note: {
        createdAt: null,
        createdBy: null,
        text: null,
        assignedUsers: [],
      },
    };
  },
  watch: {
    async selectedOrder(e) {
      if (e) {
        this.order = JSON.parse(JSON.stringify(this.selectedOrder));
        const image = this.order.images.filter((i) => i.type.includes("image"));
        this.displayedImage = image[0] || null;
        this.order.productImageDataUrl = await this.fetchProductImage(
          this.order
        );
      }
    },
  },
  methods: {
    calculateCurrentTrezzaPrice(currentPrice, origin) {
      if (origin === "Lab Grown") {
        return parseFloat(((currentPrice - 135) / 1.2 / 1.3 / 1.06).toFixed(2));
      } else {
        return parseFloat(
          ((currentPrice - 135) / 1.15 / 1.2 / 1.06).toFixed(2)
        );
      }
    },
    async fetchProductImage(order) {
      try {
        const image = order.images.filter((i) => i.type.includes("image"));
        const displayedImage = image[0] || null;
        return await this.getBase64ImageFromUrl(displayedImage.url);
      } catch (error) {
        console.debug("Error fetching product image:", error);
      }
    },
    getBase64ImageFromUrl(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = function () {
          reject("Error loading image");
        };
        img.src = url;
      });
    },
    exportOrder(option) {
      switch (option) {
        case "pdf":
          this.exportToPDF();
          break;

        case "csv":
          this.exportToExcel();
          break;
      }
    },
    async exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      // Example data
      const data = [
        [
          "Order Number",
          "PO Number",
          "Jewelry Details",
          "Engraving",
          "Birthstones",
        ],
        [
          this.order.orderNumber,
          this.order.purchaseOrderNumber,
          `Name: ${this.order.style}\nShape: ${
            this.shapeType[this.order.shapeType]
          }\nCarat: ${this.addCTTW(this.order)} \nMetal: ${
            this.metals[this.order.metal]
          } \nSize: ${this.order.ringSize}`,
          `Message: ${
            this.order.engraving ? this.order.engraving.text : "None"
          }\nFont: ${
            this.order.engraving ? this.order.engraving.font : "None"
          }`,
          `${
            this.order.birthStones.length
              ? this.order.birthStones.map((s) => s.name).join("\n")
              : "None"
          } `,
        ],
      ];

      // Add data to the worksheet
      data.forEach((row) => {
        const excelRow = worksheet.addRow(row);

        // Apply text wrap and center alignment
        excelRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "justify",
            horizontal: "left",
            wrapText: true,
          };
        });
      });

      worksheet.columns.forEach((column) => {
        column.width = 45; // Adjust the width as needed
      });

      // Set response headers for download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.order.purchaseOrderNumber}.xlsx`;
      link.click();
    },
    async exportToPDF() {
      const order_container_ref = this.$refs.order_receipt_container;
      const pdf = new jsPDF();
      const content = order_container_ref.$refs.contentToExport; // Replace with your element's ref or selector
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 10, 200, 200);
      pdf.save(`${this.order.purchaseOrderNumber}.pdf`);
      this.setStatus();
    },
    setStatus() {
      if (this.order.status == "Order Created") {
        this.order.status = 'In Process'
        const orderRef = doc(db, "orders", this.order.id);
        return updateDoc(orderRef, { status: "In Process" });
      }
    },
    enlargeImage(img) {
      this.selectedImage = img.url;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    addCTTW(order) {
      const cctw = order.sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
  },
};
</script>

<style scoped>
/* IDS */

#order-detail-export-dropdown {
  margin-top: -11px !important;
  /* margin-left: -144px !important; */
  opacity: 1 !important;
  pointer-events: auto !important;
}
#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */
.order-receipt {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.note-label {
  display: flex;
}
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.info-container {
  width: 100%;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
  margin-bottom: 30px;
}
.form {
  display: flex;
}

.helper-icon {
  width: 20px;
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  background-color: #47bb47;
  color: white;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}
.helper {
  position: absolute;
  width: 150px;
  background-color: #37b333;
  margin-left: 105px;
  text-align: initial;
  color: white;
  padding: 10px;
  font-size: 12px;
  z-index: 100;
}
.form-group {
  width: 250px;
  margin-right: 50px;
}
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 150px;
  cursor: zoom-in;
}
.images {
  display: flex;
  width: 145px;
  justify-content: center;
}

.checkbox-wrapper {
  display: flex;
  gap: 11px;
}

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}
</style>
