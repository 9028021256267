<template>
  <div>
    <h5 class="font-weight-bolder">Order Information</h5>

    <div class="multisteps-form__content">
      <div class="row">
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Collection <span class="required">*</span></label>
          <v-select
            :appendToBody="false"
            :deselectFromDropdown="true"
            v-model="order.collection"
            :options="collections"
            :clearable="false"
            label="title"
            :reduce="(c) => Object.assign({ id: c.id, title: c.title })"
            :closeOnSelect="true"
          >
          </v-select>
        </div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Company/Client <span class="required">*</span></label>
          <v-select
            :appendToBody="false"
            :deselectFromDropdown="true"
            v-model="selectedClient"
            :options="clients"
            :clearable="false"
            label="company"
            :closeOnSelect="true"
          >
          </v-select>
          <sub>Selecting Client Will Auto Populate Address Info</sub>
        </div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Origin<span class="required">*</span></label>

          <select
            type="text"
            v-model="order.origin"
            class="form-control"
            placeholder=" "
            required
          >
            <option
              v-for="origin in ['Lab Grown', 'Natural']"
              :value="origin"
              :key="origin"
            >
              {{ origin }}
            </option>
          </select>
        </div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Jewelry Type<span class="required">*</span></label>

          <select
            type="text"
            v-model="order.jewelryType"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option v-for="type in jewelryTypes" :value="type" :key="type">
              {{ type }}
            </option>
          </select>
        </div>

        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Style<span class="required">*</span></label>

          <select
            style="cursor: pointer"
            type="text"
            v-model="order.style"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option
              v-for="style in styles[order.jewelryType]"
              :value="style"
              :key="style"
            >
              {{ style }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label>Quality<span class="required">*</span></label>
          <select
            style="cursor: pointer"
            v-model="order.quality"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option
              v-for="quality in ['F-G/VS', 'H-I/SI']"
              :value="quality"
              :key="quality"
              :disabled="quality == 'H-I/SI' && order.origin == 'Lab Grown'"
            >
              {{ quality }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label>Shape<span class="required">*</span></label>
          <select
            style="cursor: pointer"
            v-model="order.shape"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option v-for="shape in shapeOptions" :value="shape" :key="shape">
              {{ shape }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6">
          <label>Metal<span class="required">*</span></label>
          <select
            style="cursor: pointer"
            v-model="order.metal.name"
            @change="handleMetalInput"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option
              v-for="metal in [
                '14K White Gold',
                '14K Yellow Gold',
                '14K Rose Gold',
                '18K White Gold',
                '18K Yellow Gold',
                '18K Rose Gold',
                'Platinum',
              ]"
              :value="metal"
              :key="metal"
            >
              {{ metal }}
            </option>
          </select>
        </div>

        <div v-if="order.jewelryType == 'Ring'" class="col-12 col-sm-6">
          <label>Ring Size<span class="required">*</span></label>
          <select
            style="cursor: pointer"
            v-model="order.size"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option v-for="size in ringSizes" :value="size" :key="size">
              {{ size }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label>Approximate Carat Weight<span class="required">*</span></label>
          <select
            style="cursor: pointer"
            v-model="order.caratWeight"
            name="floating_first_name"
            id="floating_first_name"
            class="form-control"
            placeholder=" "
            required
          >
            <option
              v-for="carat in caratWeightOptions"
              :value="carat"
              :key="carat"
            >
              {{ carat }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6">
          <label>Product's SKU</label>
          <input
            :value="selectedProduct.sku"
            class="multisteps-form__input form-control"
            type="text"
            disabled
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Product's Price</label>
          <input
            :value="format_price(selectedProduct.totalPrice)"
            class="multisteps-form__input form-control"
            type="text"
            disabled
          />
        </div>
      </div>

      <!-- <div class="mt-1 row">
        <div class="col-12 col-sm-6">
          <label>Stock # (For DIS Purposes)</label>
          <input
            v-model="order.StockNumber"
            class="multisteps-form__input form-control"
            type="text"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Client SKU</label>
          <input
            v-model="order.ClientSKU"
            class="multisteps-form__input form-control"
            type="text"
          />
        </div>
      </div> -->

      <div v-if="productImage" class="row">
        <h5 class="font-weight-bolder mt-3">Product's Image</h5>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <img style="width: 35%" :src="productImage.url" alt="" srcset="" />
        </div>
      </div>

      <div class="row">
        <h5 class="font-weight-bolder mt-3">Custom Options</h5>
        <div v-if="order.jewelryType == 'Ring'" class="col-12 col-sm-6">
          <label>Engravings (optional)</label>
          <input
            type="text"
            @input="handleEngravings"
            name="floating_last_name"
            id="floating_last_name"
            class="form-control"
            placeholder=" "
          />
        </div>
        <div class="col-12 col-sm-6">
          <div style="margin-top: 1rem">
            <label
              >Travel
              {{ order.jewelryType }}
              (optional)</label
            >
          </div>

          <input
            type="radio"
            v-model="order.addedTravelItem"
            name="floating_last_name"
            id="floating_last_name"
            :value="true"
          />
          Yes

          <input
            type="radio"
            v-model="order.addedTravelItem"
            name="floating_last_name"
            id="floating_last_name"
            :value="false"
          />
          No
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
          <!-- <div class="col-12 col-sm-6">
            <select
              
              v-model="order.birthStones"
              name="floating_first_name"
              id="floating_first_name"
              class="form-control"
              placeholder=" "
              required
            >
              <option
                v-for="(image, key) in birthStones"
                :value="image"
                :key="key"
              >
                <div style="text-transform: capitalize" class="birthstones">
                  <span >{{ key }}</span>
                  <img style="width: 50px" :src="image" alt="" srcset="" />
                </div>
              </option>
            </select>
            <label
              for="floating_first_name"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[silverbd] peer-focus:dark:text-[silverbd] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Birthstones</label
            >
          </div> -->
        </div>
      </div>
      <div class="row">
        <h5 class="font-weight-bolder mt-4">Pricing Information</h5>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label>Quantity</label>
          <input
            v-model="order.quantity"
            class="multisteps-form__input form-control"
            type="number"
            placeholder="1"
            min="1"
          />
        </div>

        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label
            >Store's Cost
            <sub v-if="!selectedProduct" style="color: red"
              >(Approx) This does not reflect final price for
              <strong style="text-decoration: underline; font-size: small"
                >Special Orders</strong
              >. Final Price will be given after review</sub
            ></label
          >
          <input
            disabled
            :value="format_price(storeTotalCost)"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="$99.00"
          />
        </div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0"></div>
        <div class="mt-3 col-12 col-sm-6 mt-sm-0">
          <label
            >Consumer Total Cost
            <sub style="color: red">Not Including Sales Tax</sub></label
          >
          <input
            disabled
            :value="format_price(consumerCost)"
            class="multisteps-form__input form-control"
            type="text"
            placeholder="$99.00"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <form @submit.prevent="">
            <h5 class="font-weight-bolder">Consumer Information</h5>
            <div class="row">
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>First Name<span class="required">*</span></label>
                <input
                  type="text"
                  v-model="consumerForm.firstName"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="multisteps-form__input form-control"
                  required
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Last Name<span class="required">*</span></label>
                <input
                  type="text"
                  v-model="consumerForm.lastName"
                  name="floating_last_name"
                  id="floating_last_name"
                  class="multisteps-form__input form-control"
                  placeholder=" "
                  required
                />
              </div>
              <!-- <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Email address<span class="required">*</span></label>
                <input
                  type="email"
                  v-model="consumerForm.email"
                  name="floating_email"
                  id="floating_email"
                  class="multisteps-form__input form-control"
                  placeholder=" "
                  required
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Phone Number <span class="required">*</span></label>
                <input
                  type="tel"
                  v-model="consumerForm.phone"
                  @input="formatPhoneNumber"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  name="floating_phone"
                  id="floating_phone"
                  class="multisteps-form__input form-control"
                  placeholder="EX: 123-456-7890"
                  required
                />
              </div> -->
            </div>

            <h5 class="font-weight-bolder">Store Address</h5>

            <div class="row">
              <div class="col-sm-6">
                <label
                  style="text-transform: uppercase; text-decoration: underline"
                  for=""
                  >Shipping</label
                >
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="col-sm-8 mt-sm-0">
                    <label>Street Address<span class="required">*</span></label>
                    <input
                      type="text"
                      disabled
                      v-model="consumerForm.shipping.addressOne"
                      name="floating_phone"
                      id="floating_phone"
                      class="multisteps-form__input form-control"
                      placeholder=" "
                      required
                    />
                  </div>
                  <div class="col-sm-8 mt-sm-0">
                    <label>State<span class="required">*</span></label>
                    <input
                      type="text"
                      disabled
                      v-model="consumerForm.shipping.state"
                      name="state"
                      id="state"
                      class="multisteps-form__input form-control"
                      placeholder=" "
                      required
                    />
                  </div>
                </div>
                <div class="grid md:grid-cols-2 md:gap-6">
                  <div class="col-sm-8 mt-sm-0">
                    <label>City<span class="required">*</span></label>
                    <input
                      type="text"
                      disabled
                      name="city"
                      v-model="consumerForm.shipping.city"
                      id="city"
                      class="multisteps-form__input form-control"
                      placeholder=" "
                      required
                    />
                  </div>
                  <div class="col-sm-8 mt-sm-0">
                    <label>Postal Code<span class="required">*</span></label>
                    <input
                      type="text"
                      disabled
                      v-model="consumerForm.shipping.zipCode"
                      name="zip"
                      id="zip"
                      class="multisteps-form__input form-control"
                      placeholder=" "
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <label
                  style="text-transform: uppercase; text-decoration: underline"
                  for=""
                  >Billing</label
                >
                <!-- <span
                  style="
                    font-size: 11px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    text-decoration: underline;
                  "
                  >SAME AS SHIPPING
                  <input
                    @change="handleCheckbox($event)"
                    style="height: 10px; width: 10px; margin-left: 5px"
                    type="checkbox"
                    v-model="sameAsShipping"
                /></span> -->

                <div class="mt-3 col-12 col-sm-8 mt-sm-0">
                  <label>Street Address<span class="required">*</span></label>
                  <input
                    type="text"
                    disabled
                    v-model="consumerForm.billing.addressOne"
                    name="floating_phone"
                    id="floating_phone"
                    class="multisteps-form__input form-control"
                    placeholder=" "
                    required
                  />
                </div>
                <div class="mt-3 col-12 col-sm-8 mt-sm-0">
                  <label>State<span class="required">*</span></label>

                  <input
                    type="text"
                    disabled
                    v-model="consumerForm.billing.state"
                    name="state"
                    id="state"
                    class="multisteps-form__input form-control"
                    placeholder=" "
                    required
                  />
                </div>
                <div class="mt-3 col-12 col-sm-8 mt-sm-0">
                  <label>City<span class="required">*</span></label>
                  <input
                    type="text"
                    disabled
                    v-model="consumerForm.billing.city"
                    name="city"
                    id="city"
                    class="multisteps-form__input form-control"
                    placeholder=" "
                    required
                  />
                </div>
                <div class="mt-3 col-12 col-sm-8 mt-sm-0">
                  <label>Postal Code<span class="required">*</span></label>
                  <input
                    type="text"
                    disabled
                    v-model="consumerForm.billing.zipCode"
                    name="zip"
                    id="zip"
                    class="multisteps-form__input form-control"
                    placeholder=" "
                    required
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">
        <soft-button
          :disabled="loading"
          @click="validate"
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
        >
          <span v-if="!loading">Create</span>
          <i v-else class="fas fa-spinner fa-spin fa-lg"></i>
        </soft-button>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import SoftButton from "@/components/SoftButton.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import Choices from "choices.js";
import axios from "axios";
import {
  collection,
  doc,
  db,
  query,
  getDoc,
  where,
  getDocs,
} from "@/config/firebaseInit.js";

export default {
  name: "ProductInfo",
  components: {
    SoftButton,
    SoftSwitch,
  },
  props: {
    clients: {
      type: Array,
      default: [],
    },
  },
  emits: ["close-modal"],
  data() {
    return {
      styles: {
        Earring: [
          "Studs", // Simple, discreet, and elegant, studs are versatile and appropriate for the widest range of occasions and looks.
          "Hoops", // Traditionally, hoops are circular, but as they have grown in popularity, you will see all sorts of different shapes such as squares, ovals, or triangles[^2^][2].
          "Drops", // A drop earring will connect at the earlobe, but instead of resting against the ear, the drop earring will hang down or drop below the ear[^2^][2].
          "Dangles", // Dangles are similar to drop earrings, but the difference is that drop earrings are typically stationary, whereas dangles are free to swing back and forth[^2^][2].
          "Chandeliers", // Chandelier earrings get their name because they are shaped like a chandelier, but in a lot of cases, they might dangle as well[^2^][2].
          "Barbells", // A barbell earring has a thin, straight metal rod with a bead permanently fixed to one end. The other end is removable to allow the earring to be inserted into a piercing.
          "Ear Cuffs", // Ear cuffs wrap around the outer cartilage of the ear.
          "Threaders", // Threader earrings have a thin chain attached to a metal post, allowing them to be threaded through the ear.
          "Cluster Earrings", // These earrings have clusters of gemstones or other decorative elements[^3^][5].
          "Tassel Earrings", // These earrings feature hanging threads or chains[^3^][5].
          "Long Chain Earrings", // These earrings have long chains that can dangle below the shoulder[^3^][5].
        ],
        Ring: [
          "Eternity Band",
          "Anniversary Band",
          "Wedding Ring",
          "Engagement Ring",
          "Fashion RIng",
        ],
        Bracelet: ["Tennis", "Pearl", "Charm", "Bangle", "Beaded", "Chain"],
        Necklace: [
          "Tennis",
          "Pendant",
          "Chain", // Simple, minimal wardrobe staple.
          "Choker", // Worn around the neck, tends to be a bit wider to make up for its lack of length.
          "Collar", // A broad, often solid piece of jewelry that rides close to the neck.
          "Festoon", // A style of necklace that has multiple chains or strings draping in a swag.
          "Graduated", // A style where the size of the elements increases towards the center.
          "Lariat", // A long necklace that is typically tied or looped around the neck.
          "Lavalier", // A necklace with a pendant that has dangling stones.
          "Locket", // A pendant that opens to reveal a space used for storing a photograph or other small item.
          "Matinee", // A single or multi-strand necklace that hangs just below the bust.
          "Opera", // A long necklace that can be worn as a single or double strand.
          "Pendant", // A jewel, commonly a slide, locket, or charm, that is worn suspended from a chain.
          "Princess", // A necklace length that is longer than a choker but shorter than a matinee.
          "Riviere", // A necklace that is composed of a single strand of gemstones of the same general size and shape.
          "Sautoir", // A long necklace ending in tassels or a pendant.
          "Torque", // A rigid necklace that is worn open ended at the front.
        ],
      },
      styleOptions: [
        "Pendant",
        "Necklace",
        "Bracelet",
        "Hoops",
        "Ring",
        "Rings",
        "Earring",
        "Watch",
        "Solitaire",
        "Brooch",
        "Studs",
        "Watch Bezel",
        "Wedding Set",
      ],
      travelJewelryCosts: {
        Ring: 100,
        Earring: 200,
        Bracelet: 300,
        Necklace: 400,
      },
      order: {
        images: [],
        quantity: 1,
        addedTravelItem: false,
        totalCost: 0,
        jewelryType: "",
        caratWeight: "",
        size: "",
        origin: "",
        shape: "",
        sku: "",
        style: "",
        quality: "",
        birthStones: [],
        engravings: "",
        priceBreakdown: {
          itemUnitPrice: 0,
          totalCost: 0,
          trezzaPrice: 0,
          ccFee: 0,
          achFee: 0,
          storeTotalCost: 0,
          travelJewelryCosts: 0,
        },
        metal: {
          gram: "",
          colorName: "",
          metal: "",
          name: "",
        },
      },
      consumerForm: {
        storeId: null,
        firstName: "",
        lastName: "",
        billing: {
          addressOne: null,
          addressTwo: null,
          city: null,
          state: null,
          zipCode: null,
        },
        shipping: {
          addressOne: null,
          addressTwo: null,
          city: null,
          state: null,
          zipCode: null,
        },
      },
      qualityOption: ["F-G/VS", "H-I/SI"],
      collections: [],
      products: [],
      jewelryTypes: ["Ring", "Necklace", "Bracelet", "Earring"],
      settings: [],
      selectedCollection: null,
      selectedClient: null,
      engravingsAdded: false,
      productImage: null,
      loading: false,
      bccEmails: [],
      totalPrice: 0,
    };
  },
  computed: {
    caratWeightOptions() {
      let carats = [];
      const allValidCarats = [];
      let selectedSize = this.order.style.includes("Eternity Band")
        ? this.order.size
        : Math.floor(this.order.size);

      if (this.order.jewelryType == "Ring") {
        carats = this.products.map((product) =>
          product.prices
            .filter(
              (price) =>
                price.size == selectedSize &&
                price.metalData.gram == this.order.metal.gram &&
                price.metalData.metal.toLowerCase() ==
                  this.order.metal.metal.toLowerCase() &&
                price.origin == this.order.origin
            )
            .map((s) => s.caratWeight)
        );
      } else {
        carats = this.products.map((product) =>
          product.prices
            .filter(
              (price) =>
                price.metalData.gram == this.order.metal.gram &&
                price.metalData.metal.toLowerCase() ==
                  this.order.metal.metal.toLowerCase() &&
                price.origin == this.order.origin &&
                this.formatQuality(price.diamondQuality) == this.order.quality
            )
            .map((s) => s.caratWeight)
        );
      }

      carats = [].concat(...carats);

      carats.forEach((c) => {
        if (!allValidCarats.includes(c)) allValidCarats.push(c);
      });
      return allValidCarats.sort();
    },
    ringSizes() {
      let ringSizes = [];
      for (let index = 5; index <= 10; index += 0.25) {
        ringSizes.push(index);
      }
      return ringSizes;
    },
    storeTotalCost: {
      // getter
      get() {
        let cost = 0;
        if (this.totalPrice) {
          cost = this.totalPrice;
        }
        if (this.order.engravings.text) {
          cost += 50;
        }
        if (this.order.addedTravelItem) {
          cost += this.travelJewelryCosts[this.order.jewelryType];
        }

        return cost * this.order.quantity;
      },
      // setter
      set(newValue) {
        this.totalPrice = Number(newValue);
      },
    },
    consumerCost() {
      let cost = 0;
      if (this.totalPrice) {
        cost = this.totalPrice * 2;
      }
      if (this.order.engravings.text) {
        cost += 50;
      }
      if (this.order.addedTravelItem) {
        cost += this.travelJewelryCosts[this.order.jewelryType];
      }

      return cost * this.order.quantity;
    },
    shapeOptions() {
      return ["Round", "Oval", "Emerald"];
    },
    selectedProduct() {
      let selectedProduct = { totalPrice: 0 };
      const order = this.order;
      let selectedSize = order.style.includes("Eternity Band")
        ? order.size
        : Math.floor(order.size);
      this.products.forEach((product) => {
        const foundProduct = product.prices.find(
          (p) =>
            p.caratWeight == order.caratWeight &&
            (order.jewelryType === "Ring" ? p.size == selectedSize : true) &&
            p.metalData.gram == order.metal.gram &&
            p.metalData.metal.toLowerCase() ==
              order.metal.metal.toLowerCase() &&
            p.origin == order.origin &&
            `${p.diamondQuality.colors.from}-${p.diamondQuality.colors.to}/${p.diamondQuality.clarity.from}` ==
              order.quality
        );

        if (foundProduct) {
          selectedProduct = Object.assign({
            shape: product.shape,
            mediaFiles: product.mediaFiles.filter((m) =>
              m.type.includes("image")
            ),
            sku: product.sku,
            totalPrice: foundProduct.totalPrice,
          });
        }
      });

      return selectedProduct;
    },
  },
  watch: {
    "order.metal.name"(val) {
      const metal = val.split(" ");
      if (val.includes("Gold")) {
        this.order.metal.gram = metal[0].toLowerCase();
        this.order.metal.metal = metal[2].toLowerCase();
        this.order.metal.colorName = metal[1] + " " + metal[2];
      } else {
        this.order.metal.gram = null;
        this.order.metal.metal = metal[0];
        this.order.metal.colorName = metal[0];
      }
    },

    "order.origin"(data) {
      if (data && this.order.jewelryType && this.order.style) {
        this.fetchCollectionProducts(
          this.order.collection.id,
          this.order.jewelryType,
          this.order.origin,
          this.order.style
        );
      }
    },
    "order.style"(data) {
      if (data) {
        this.fetchCollectionProducts(
          this.order.collection.id,
          this.order.jewelryType,
          this.order.origin,
          this.order.style
        );
      }
    },
    "order.jewelryType"(dataBfor, dataAfter) {
      if (dataAfter && dataBfor !== dataAfter) {
        this.selectedProduct = null;
      }
    },
    selectedProduct(data) {
      if (data.totalPrice) {
        this.totalPrice = data.totalPrice;
        this.productImage = this.selectedProduct.mediaFiles.find((file) =>
          file.metalColor.includes(this.order.metal.colorName)
        );
        this.order.images.push(this.productImage);
      }
    },
    selectedClient(data) {
      if (data) {
        this.order.client = Object.assign({
          id: data.id,
          company: data.company,
          email: data.email,
          fullName: `${data.firstName} ${data.lastName}`,
          refNum: data.refNumber,
        });
        this.order.salesRep = data.salesRep;
        this.consumerForm.storeId = data.stores[0];
        this.consumerForm.shipping = data.address;
        this.consumerForm.billing = data.address;
      }
    },
  },
  created() {
    this.getCollections();
    this.fetchBCCEmails();
  },
  mounted() {
    if (document.getElementById("edit-description")) {
      // eslint-disable-next-line no-unused-vars
      var quill = new Quill("#edit-description", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-currency")) {
      let element = document.getElementById("choices-currency");
      new Choices(element, {
        searchEnabled: false,
      });
    }

    if (document.getElementById("choices-tags")) {
      var tags = document.getElementById("choices-tags");
      const examples = new Choices(tags, {
        removeItemButton: true,
      });

      examples.setChoices(
        [
          {
            value: "One",
            label: "Expired",
            disabled: true,
          },
          {
            value: "Two",
            label: "Out of Stock",
            selected: true,
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  methods: {
    formatQuality(data) {
      const colors = `${data.colors.from}${
        data.colors.to ? "-" + data.colors.to : ""
      }`;

      const clarity = `${data.clarity.from}${
        data.clarity.to ? "-" + data.clarity.to : ""
      }`;

      return `${colors}/${clarity}`;
    },
    handleEngravings(e) {
      const value = e.target.value;
      if (value) {
        this.order.engravings = {
          font: "Tangerine, serif",
          text: value,
        };
      } else {
        this.order.engravings = "";
      }
    },
    handleMetalInput() {
      this.order.metal.gram = this.order.metal.metal
        .split(" ")[0]
        .toLowerCase();
    },
    reset() {
      this.order = {
        images: [],
        quantity: 1,
        addedTravelItem: false,
        totalCost: 0,
        jewelryType: "",
        caratWeight: "",
        size: "",
        origin: "",
        shape: "",
        sku: "",
        style: "",
        quality: "",
        birthStones: [],
        engravings: "",
        priceBreakdown: {
          itemUnitPrice: 0,
          totalCost: 0,
          trezzaPrice: 0,
          ccFee: 0,
          achFee: 0,
          storeTotalCost: 0,
          travelJewelryCosts: 0,
        },
        metal: {
          gram: "",
          colorName: "",
          metal: "",
          name: "",
        },
      };
      this.consumerForm = {
        storeId: null,
        firstName: "",
        lastName: "",
        // email: "",
        // phone: "",
        // company: null,
        billing: {
          addressOne: null,
          addressTwo: null,
          city: null,
          state: null,
          zipCode: null,
        },
        shipping: {
          addressOne: null,
          addressTwo: null,
          city: null,
          state: null,
          zipCode: null,
        },
      };
      this.selectedCollection = null;
      this.selectedClient = null;
      this.engravingsAdded = false;
      this.productImage = null;
      this.totalPrice = 0;

      this.$emit("close-modal");
    },

    validate() {
      let valid = true;
      let order_inputs = [
        "jewelryType",
        "caratWeight",
        "origin",
        "shape",
        "quality",
        "metal",
      ];

      if (this.order.jewelryType === "Ring") {
        order_inputs.push("size");
      }

      let form_input = ["firstName", "lastName"];

      order_inputs.forEach((input) => {
        if (input == "metal") {
          for (const key in this.order[input]) {
            if (Object.prototype.hasOwnProperty.call(this.order[input], key)) {
              if (this.order[input].metal == "Platinum") {
                return;
              }
              const element = this.order[input][key];
              if (!element) {
                valid = false;
              }
            }
          }
        } else if (!this.order[input]) {
          valid = false;
        }
      });

      form_input.forEach((input) => {
        if (!this.consumerForm[input]) {
          valid = false;
        }
      });

      if (valid) this.saveOrder();
      else this.$swal.fire("Must fill in all required fields");
    },
    async saveOrder() {
      this.loading = true;
      this.order.consumer = this.consumerForm;
      this.order.totalCost = this.storeTotalCost;
      this.order.paymentStatus = "Not Paid";
      this.order.paymentType = "System Order - Client Must Pay";
      this.order.priceBreakdown.travelJewelryCosts = this.order.addedTravelItem
        ? this.travelJewelryCosts[this.order.jewelryType]
        : 0;
      this.order.priceBreakdown.storeTotalCost = this.storeTotalCost;
      this.order.consumerTotalPrice = this.consumerCost;
      this.order.originalPrice = this.selectedProduct.totalPrice;

      this.order.sku = `${this.selectedProduct.sku}-${
        this.order.jewelryType !== "Ring" ? "" : "S" + this.order.size + "-"
      }${
        !this.order.metal.gram ? "PLAT" : this.order.metal.gram.replace("k", "")
      }${this.formatColorName(
        this.order.metal.colorName
      )}-${this.formatMaterialForOrderSKU(
        this.order.origin,
        this.order.quality
      )}`;

      let res = await axios.post(
        `https://us-central1-${
          process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
        }.cloudfunctions.net/LunaCollectionServer/add_order`,
        this.order
      );

      await this.sendConfirmationEmail(res.data);
      this.loading = false;
      this.reset();
      this.$swal.fire("Your order has been placed.");
    },
    formatSettingForOrderSKU(setting) {
      if (setting.includes("Graduated")) {
        return "GRAD";
      } else if (setting.includes("Prongs")) {
        const settings = setting.split(" ");
        return `${settings[0]}PR`;
      } else {
        return setting;
      }
    },
    formatColorName(name) {
      if (name.includes("Platinum")) return "";
      const names = name.split(" ");
      return `${names[0][0]}${names.length > 1 ? names[1][0] : ""}`;
    },
    formatMaterialForOrderSKU(origin, quality) {
      if (origin == "Lab Grown") {
        return "LG";
      } else {
        if (quality.includes("F-G/VS")) return "V";
        else return "S";
      }
    },
    async fetchCollectionProducts(id, jewelryType, origin, style) {
      this.products = [];
      const colRef = collection(db, "collections", id, "products");

      const orderQuery = query(
        colRef,
        where("jewelryType", "==", jewelryType),
        where("style", "==", style)
      );
      let products = await getDocs(orderQuery);
      this.products = products.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    async getCollections() {
      const colRef = collection(db, "collections");
      let collections = await getDocs(colRef);
      this.collections = collections.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    async sendConfirmationEmail(res) {
      const date = new Date();
      const createdAt = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
      try {
        return await axios.post(
          `https://us-central1-${
            process.env[`VUE_APP_${process.env.VUE_APP_MODE}_PROJECT_ID`]
          }.cloudfunctions.net/LunaCollectionServer/send_order_confirmation`,
          {
            data: {
              contents: [
                {
                  template_id: 2,
                  csv: false,
                  subject: "Luna Collection Order Confirmation Receipt",
                  filename: null,
                  cc: [this.order.client.email],
                  to: [this.order.salesRep.email],
                  bcc: this.bccEmails,
                  // cc: ["kelvin@diamondservicesusa.com"],
                  // to: ["kelvin@diamondservicesusa.com"],
                  // bcc: ["kelvin@diamondservicesusa.com"],
                  //to: [order.client.email, order.salesRep.email, ],
                  params: {
                    productImage: this.order.images[0].url,
                    clientName: this.order.client.fullName,
                    consumerPhone: this.order.consumer.phone,
                    consumerEmail: this.order.consumer.email,
                    consumerFirstName: this.order.consumer.firstName,
                    consumerLastName: this.order.consumer.lastName,
                    purchaseOrderNumber: res.data.purchaseOrderNumber,
                    orderNumber: res.data.orderNumber,
                    createdAt,
                    itemDescription: `${this.order.origin} ${
                      this.order.jewelryType == "Ring"
                        ? `Size ${this.order.size}`
                        : ""
                    }  ${this.order.caratWeight}ct t.w ${this.order.shape} ${
                      this.order.metal.name
                    } ${this.order.style} ${this.order.jewelryType}`,
                    sku: this.order.sku,
                    carat: this.order.caratWeight,
                    quality: this.order.quality,
                    engravingsFont: this.order.engravings
                      ? this.order.engravings.font
                      : "",
                    engravingsText: this.order.engravings
                      ? this.order.engravings.text
                      : "",
                    birthStones: this.order.birthStones,
                    quantity: this.order.quantity,
                    totalCost: this.format_price(this.order.totalCost),
                  },
                },
              ],
            },
          }
        );
      } catch (err) {
        this.orderBeingProcessed = false;
        // orderFeedback.value = err.message;
        console.debug(err);
        this.displayError(err);
      }
    },
    async fetchBCCEmails() {
      const bccData = await getDoc(
        doc(collection(db, "attributes"), "bcc_emails")
      );
      if (bccData.exists()) this.bccEmails = bccData.data().emails;
    },
  },
};
</script>

<style scoped>
.sp-switch {
  width: 145px;
}
</style>
